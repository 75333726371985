import {
	USERS_LIST_ACTION,
	USERS_LIST_SUCCESS,
	USERS_LIST_FAILURE,
	USER_CREATE_ACTION,
	USER_CREATE_SUCCESS,
	USER_CREATE_FAILURE,
	USER_DELETE_ACTION,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAILURE,
	USER_UPDATE_ACTION,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAILURE,
	USER_ENROLL_ACTION,
	USER_ENROLL_SUCCESS,
	USER_ENROLL_FAILURE
} from '../actions';
import axios from 'axios';

import {store} from '../store';
import { error, success } from 'react-notification-system-redux';


export const users_list_set_pending = () => ({
	type: USERS_LIST_ACTION
});

export const users_list_set_successful = (res) => ({
	type: USERS_LIST_SUCCESS,
	response: res
});

export const users_list_set_failed = () => ({
	type: USERS_LIST_FAILURE
});

export const users_list_request = () => dispatch => {
	dispatch(users_list_set_pending());
	axios.get(process.env.REACT_APP_SERVER_URL + '/api/users/list',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(users_list_set_successful(res.data));
		} else {
			dispatch(users_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(users_list_set_failed());
	});
}


export const users_list_refresh_request = () => dispatch => {
	axios.get(process.env.REACT_APP_SERVER_URL + '/api/users/list',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(users_list_set_successful(res.data));
		} else {
			dispatch(users_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(users_list_set_failed());
	});
}






export const users_create_set_pending = () => ({
	type: USER_CREATE_ACTION
});

export const users_create_set_successful = (res) => ({
	type: USER_CREATE_SUCCESS,
	response: res
});

export const users_create_set_failed = () => ({
	type: USER_CREATE_FAILURE
});

export const users_create_request = (data) => dispatch => {
	dispatch(users_create_set_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/users/create', data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 201) {
				dispatch(users_create_set_successful(res.data));
				dispatch(users_list_refresh_request());
				resolve(true);
			} else {
				dispatch(users_create_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(users_create_set_failed());
			reject(err.response.data);
		});
	});
}





export const users_delete_set_pending = (userId) => ({
	type: USER_DELETE_ACTION,
	userId: userId
});

export const users_delete_set_successful = (res) => ({
	type: USER_DELETE_SUCCESS,
	response: res
});

export const users_delete_set_failed = () => ({
	type: USER_DELETE_FAILURE
});

export const users_delete_request = (userId) => dispatch => {
	dispatch(users_delete_set_pending(userId));
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + '/api/users/delete/' + userId,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(users_delete_set_successful(res.data));
				dispatch(users_list_refresh_request());
				resolve(true);
			} else {
				dispatch(users_delete_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(users_delete_set_failed());
			reject(err.response.data);
		});
	});
}





export const users_update_set_pending = () => ({
	type: USER_UPDATE_ACTION
});

export const users_update_set_successful = (res) => ({
	type: USER_UPDATE_SUCCESS,
	response: res
});

export const users_update_set_failed = () => ({
	type: USER_UPDATE_FAILURE
});


export const users_update_request = (userId, data) => dispatch => {
	dispatch(users_update_set_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/users/update/' + userId, data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(users_update_set_successful(res.data));
				dispatch(users_list_refresh_request());
				resolve(true);
			} else {
				dispatch(users_update_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(users_update_set_failed());
			reject(err.response.data);
		});
	});
}


export const user_enroll_set_pending = () => ({
	type: USER_ENROLL_ACTION
});

export const user_enroll_set_successful = (res) => ({
	type: USER_ENROLL_SUCCESS,
	response: res
});

export const user_enroll_set_failed = () => ({
	type: USER_ENROLL_FAILURE
});

export const users_enroll_request = (userId) => dispatch => {
	dispatch(user_enroll_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + '/api/users/enroll/' + userId,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(user_enroll_set_successful(res.data));
				dispatch(users_list_refresh_request());
				dispatch(success({title: "Operation is Successful", message: "Enroll mail has been sent."}))
				resolve(true);
			} else {
				dispatch(user_enroll_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(user_enroll_set_failed());
			reject(err.response.data);
		});
	});
}