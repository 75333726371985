const admin_pages = [
	{
		name: "Customers",
		icon: "server",
		url: '/customers',
		roles: ["admin","developer"]
	},
	{
		name: "Users",
		icon: "users",
		url: '/users',
		roles: ["admin", "developer"]
	},
	{
		name: "Licenses",
		icon: "file",
		url: '/licenses',
		roles: ["admin", "developer"]
	},
	{
		name: "Releases",
		icon: "download",
		url: '/releases',
		roles: ["admin","developer"]
	},
	{
		name: "Issues",
		icon: "help",
		url: '/issues',
		roles: ["admin","developer"]
	},
	{
		name: "My Profile",
		icon: "user",
		url: '/profile',
		roles: ["admin","developer"]
	},
	// {
	// 	name: "Products",
	// 	icon: "server",
	// 	url: '/products',
	// 	roles: ["admin","developer"]
	// },
	// {
	// 	name: "Price Lists",
	// 	icon: "dollar",
	// 	url: '/pricelists',
	// 	roles: ["admin","developer"]
	// },
	// {
	// 	name: "Distributors",
	// 	icon: "server",
	// 	url: '/distributors',
	// 	roles: ["admin","developer"]
	// },
	
	
	// {
	// 	name: "Orders",
	// 	icon: "server",
	// 	url: '/orders',
	// 	roles: ["admin","developer"]
	// },
	// {
	// 	name: "New Order",
	// 	icon: "plus",
	// 	url: '/new-order',
	// 	roles: ["admin","developer"]
	// },
	
	
	
	
]

const customer_pages = [
	{
		name: "Software Releases",
		icon: "download",
		url: '/releases',
		roles: ["customer"]
	},
	{
		name: "My Licenses",
		icon: "server",
		url: '/licenses',
		roles: ["customer"]
	},
	{
		name: "My Issues",
		icon: "edit",
		url: '/issues',
		roles: ["customer"]
	},
	{
		name: "My Profile",
		icon: "user",
		url: '/profile',
		roles: ["customer"]
	},
]

export const pages = [...admin_pages, ...customer_pages];