import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message, Checkbox, Segment } from 'semantic-ui-react'
import { Translate, I18n } from "react-redux-i18n";
import { customers_create_request } from 'redux/customers/actions';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import 'style/markdown.css';
import { software_releases_update_request } from 'redux/software_releases/actions';

class Software_Releases_Modify extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			software_name: "",
			release_version: "",
			minimum_license_version: "",
			release_note: "", 
			replace_file1: false,
			file1: null,
			replace_file2: false,
			file2: null,
			show_markdown: false,
			allowed_customers: [],
			errorMessage: ""
		}

		this.updateContent = this.updateContent.bind(this);
	}
	componentDidMount(){
		this.updateContent();

	}

	updateContent(){
		this.setState({
			software_name: this.props.data?.software_name,
			release_version: this.props.data?.release_version,
			minimum_license_version: this.props.data?.minimum_license_version,
			release_note: this.props.data?.release_note,
			allowed_customers: this.props.data?.allowed_customers,
			replace_file1: false,
			file1: null,
			replace_file2: false,
			file2: null,
			show_markdown: false,
			errorMessage: ""
		});
	}

	componentDidUpdate(prevProps){
		if(this.props.data?._id !== prevProps.data?._id){
		  this.updateContent();
		}
	}
	
	submitForm(){
		console.log("Submit");
		
		this.setState({errorMessage: null}, () => {
			
			let {open, errorMessage, show_markdown, ...rest} = this.state;
			this.props.dispatch(software_releases_update_request(this.props.data?._id, rest))
			.then((resolved , rejected) => {
				if(resolved){
					this.close();
				}
			})
			.catch(err => {
				this.setState({errorMessage : err.message});
			})
		});
	}

	close(){
		this.setState({open: false});
	}
	render(){
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				closeOnDimmerClick={false} 
			>
				<Header icon='plus' content={"Create Software Release"} />
				<Modal.Content>
					<Form onSubmit={() => this.onFormSubmit()}>

					
						<Form.Input 
							type='text' 
							label="Software Name" 
							name="software_name" 
							placeholder='EEforce PCB Versioning System' 
							value={this.state.software_name} 
							onChange={(e, data) => this.setState({software_name: data.value})} 
							
						/>	

						<Form.Input 
							type='text' 
							label="Release Version" 
							name="release_version" 
							placeholder='22.10' 
							value={this.state.release_version} 
							onChange={(e, data) => this.setState({release_version: data.value})} 
							
						/>	

						<Form.Input 
							type='text' 
							label="Minimum License Version" 
							name="minimum_license_version" 
							placeholder='202210' 
							value={this.state.minimum_license_version} 
							onChange={(e, data) => this.setState({minimum_license_version: data.value})} 
							
						/>			

						<Form.Select
							fluid
							label="Customer"
							multiple
							selection
							options={
								this.props.customers.data.map(cust => {
									return {
										key: cust._id,
										text: cust.name,
										value: cust._id
									}
								})
							}
							placeholder='Select Customer'
							value={this.state.allowed_customers}
							onChange={(e, data) => {this.setState({allowed_customers : data.value})}}
						/>	


						<Form.Group widths="equal">
							<Form.Field>
								<Checkbox 
									toggle 
									label='Replace File #1' 
									onChange={(e, data) => this.setState({replace_file1: data.checked})} 
									checked={this.state.replace_file1}
								/>
							</Form.Field>
							<Form.Field>
								<Checkbox 
									toggle 
									label='Replace File #2' 
									onChange={(e, data) => this.setState({replace_file2: data.checked})} 
									checked={this.state.replace_file2}
								/>
							</Form.Field>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								disabled={!this.state.replace_file1}
								type="file"
								label="Binary #1"
								name="file1"
								onChange={(e, data) => this.setState({file1: e.target.files[0]})} 
							/>
							<Form.Input
								disabled={!this.state.replace_file2}
								type="file"
								label="Binary #2"
								name="file2"
								onChange={(e, data) => this.setState({file2: e.target.files[0]})} 
							/>
						</Form.Group>
						
						<Form.Field>
							<Checkbox 
								toggle 
								label='Show Markdown' 
								onChange={(e, data) => this.setState({show_markdown: data.checked})} 
								checked={this.state.show_markdown}
							/>
						</Form.Field>

						{this.state.show_markdown ? (
							<Segment className="markdown-body" style={{maxHeight: 300, overflowY: "auto"}}>
								<ReactMarkdown remarkPlugins={[remarkGfm]}>{this.state.release_note}</ReactMarkdown>
							</Segment>
						) : (
							<Form.Input 
								label="release_note" 
								name="release_note" 
								placeholder='release_note of License file (Markdown)' 
								type='text' 
								control="textarea"
								onChange={(e, data) => {console.log(e, data); this.setState({release_note: e.target.value});}} 
								value={this.state.release_note} 
							/>
						)}
					
						
						
					</Form>
					{this.state.errorMessage ? (
						<Message error>
							<Icon name='exclamation' />
							{this.state.errorMessage}
						</Message>
					) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("users_create.cancel")}
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content={I18n.t("users_create.create")}
						color='green'
						icon="checkmark"
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
	customers: state.customers
})
export default connect(mapStateToProps)(Software_Releases_Modify)