// @flow

export const USER_LOGIN_ACTION   = "user:login:action";
export const USER_LOGIN_SUCCESS  = "user:login:success";
export const USER_LOGIN_FAILURE  = "user:login:failure";

export const USER_LOGOUT_ACTION  = "user:logout:action";
export const USER_LOGOUT_SUCCESS  = "user:logout:success";
export const USER_LOGOUT_FAILURE  = "user:logout:failure";

export const USER_REAUTH_ACTION  = "user:reauth:action";
export const USER_REAUTH_SUCCESS = "user:reauth:success";
export const USER_REAUTH_FAILURE = "user:reauth:failure";


export const USER_REGISTER_ACTION  = "user:register:action";
export const USER_REGISTER_SUCCESS = "user:register:success";
export const USER_REGISTER_FAILURE = "user:register:failure";

export const USER_FORGOT_ACTION  = "user:forgot:action";
export const USER_FORGOT_SUCCESS = "user:forgot:success";
export const USER_FORGOT_FAILURE = "user:forgot:failure";

export const USER_ENROLL_START_ACTION  = "user:enroll_start:action";
export const USER_ENROLL_START_SUCCESS = "user:enroll_start:success";
export const USER_ENROLL_START_FAILURE = "user:enroll_start:failure";

export const USER_ENROLL_COMPLETE_ACTION  = "user:enroll_complete:action";
export const USER_ENROLL_COMPLETE_SUCCESS = "user:enroll_complete:success";
export const USER_ENROLL_COMPLETE_FAILURE = "user:enroll_complete:failure";

export const USER_CHANGE_PASSWORD_ACTION  = "user:change_password:action";
export const USER_CHANGE_PASSWORD_SUCCESS = "user:change_password:success";
export const USER_CHANGE_PASSWORD_FAILURE = "user:change_password:failure";

export const USER_UPDATE_PROFILE_ACTION   = "user:update_profile:action";
export const USER_UPDATE_PROFILE_SUCCESS  = "user:update_profile:success";
export const USER_UPDATE_PROFILE_FAILURE  = "user:update_profile:failure";


export const APP_SIDEBAR_TOGGLE  = "app:sidebar:toggle";

export const APP_LOAD_CONFIGS_ACTION = "app:load_configs:action";
export const APP_LOAD_CONFIGS_SUCCESS = "app:load_configs:success";
export const APP_LOAD_CONFIGS_FAILURE = "app:load_configs:failure";

export const USERS_LIST_ACTION = "users:list:action";
export const USERS_LIST_SUCCESS = "users:list:success";
export const USERS_LIST_FAILURE = "users:list:failure";

export const USER_CREATE_ACTION =  "user:create:action";
export const USER_CREATE_SUCCESS = "user:create:success";
export const USER_CREATE_FAILURE = "user:create:failure";


export const USER_DELETE_ACTION =  "user:delete:action";
export const USER_DELETE_SUCCESS = "user:delete:success";
export const USER_DELETE_FAILURE = "user:delete:failure";

export const USER_UPDATE_ACTION =  "user:update:action";
export const USER_UPDATE_SUCCESS = "user:update:success";
export const USER_UPDATE_FAILURE = "user:update:failure";

export const USER_ENROLL_ACTION =  "user:enroll:action";
export const USER_ENROLL_SUCCESS = "user:enroll:success";
export const USER_ENROLL_FAILURE = "user:enroll:failure";

export const USER_SSO_LOGIN_ACTION = "user:sso_login:action";
export const USER_SSO_LOGIN_SUCCESS = "user:sso_login:success";
export const USER_SSO_LOGIN_FAILURE = "user:sso_login:failure";

export const RELEASES_LIST_ACTION = "software_releases:list:action";
export const RELEASES_LIST_SUCCESS = "software_releases:list:success";
export const RELEASES_LIST_FAILURE = "software_releases:list:failure";

export const LICENSE_FILES_LIST_ACTION =  "licenses:list:action";
export const LICENSE_FILES_LIST_SUCCESS = "licenses:list:success";
export const LICENSE_FILES_LIST_FAILURE = "licenses:list:failure";

export const LICENSE_FILES_UPDATE_ACTION =  "licenses:update:action";
export const LICENSE_FILES_UPDATE_SUCCESS = "licenses:update:success";
export const LICENSE_FILES_UPDATE_FAILURE = "licenses:update:failure";


export const ISSUES_LIST_ACTION =  "issues:list:action";
export const ISSUES_LIST_SUCCESS = "issues:list:success";
export const ISSUES_LIST_FAILURE = "issues:list:failure";

export const ISSUE_LOAD_ACTION =  "issue:load:action";
export const ISSUE_LOAD_SUCCESS = "issue:load:success";
export const ISSUE_LOAD_FAILURE = "issue:load:failure";


export const CUSTOMERS_LIST_ACTION =  "customers:list:action";
export const CUSTOMERS_LIST_SUCCESS = "customers:list:success";
export const CUSTOMERS_LIST_FAILURE = "customers:list:failure";

export const CUSTOMERS_CREATE_ACTION =  "customers:create:action";
export const CUSTOMERS_CREATE_SUCCESS = "customers:create:success";
export const CUSTOMERS_CREATE_FAILURE = "customers:create:failure";

export const CUSTOMERS_UPDATE_ACTION =  "customers:update:action";
export const CUSTOMERS_UPDATE_SUCCESS = "customers:update:success";
export const CUSTOMERS_UPDATE_FAILURE = "customers:update:failure";
