// import { device_list_refresh_request } from "../actions/devices-actions";
// import { device_refresh_details } from "../actions/device-details-actions";
// import { gwcommands_list_refresh_request, gwcommands_add_request } from "../actions/gwcommands-actions";

export const wsMiddleware = store => next => action => {
	if(action.type === "REDUX_WEBSOCKET::MESSAGE"){
		//console.log("Message Hadled");

		let msg = JSON.parse(action.payload.message);
		//console.log(msg);

		// if(msg && msg.collection){
		// 	switch(msg.collection){
		// 		case "light_gateway": {
		// 			if(msg.operation === "change" && (
		// 				msg.fields.includes("mac_addr") ||
		// 				msg.fields.includes("device_name") ||
		// 				msg.fields.includes("device_status") 
		// 			)) {
		// 				if(store.getState().devices.data && store.getState().devices.data.length > 0){
		// 					store.dispatch(device_list_refresh_request());
		// 				}
		// 			}

		// 			if(msg.operation === "change" && store.getState().deviceDetails.data && Object.keys(store.getState().deviceDetails.data).length > 0){
		// 				store.dispatch(device_refresh_details(store.getState().deviceDetails.data._id));
		// 			}

		// 		}



		// 		case "gateway_commands": {
		// 			switch(msg.operation){
		// 				case "insert": {
		// 					if(store.getState().deviceDetails.data && store.getState().deviceDetails.data._id === msg.deviceId){
		// 						store.dispatch(gwcommands_list_refresh_request(msg.deviceId));
		// 					}
		// 				}; break;

		// 				case "change": {
		// 					if(store.getState().gwcommands.data.find(x=> x._id == msg.objectId) != null){
		// 						store.dispatch(gwcommands_list_refresh_request(store.getState().deviceDetails.data._id));
		// 					}
		// 				}; break;

		// 				case "completion": {
		// 					//console.log("completion");
		// 					if(store.getState().gwcommands.data.find(x=> x._id == msg.objectId) != null){
		// 						store.dispatch(gwcommands_list_refresh_request(store.getState().deviceDetails.data._id));
		// 						//store.dispatch(gwcommands_add_request(store.getState().deviceDetails.data._id, "get_mesh_status", {macs: "all"}));
		// 					}
		// 				}; break;
		// 			}
		// 		}
		// 	}
		// }
	}
	return next(action)
  }