import React, { Component } from 'react';
import { Dropdown, Table, Grid, Label, Icon, Menu, Segment, Header, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link, NavLink, Route, withRouter } from 'react-router-dom'
import { Translate, I18n } from "react-redux-i18n";

import Prompt from 'pages/Prompt';
import { issues_list_request } from 'redux/issues/actions';
import Issue_Create from './Issue_Create';
import dateformat from 'tools/dateformat';

class Issues extends Component{
	constructor(props){
		super(props);
		this.state={
			filter: true
		};

	}
	componentDidMount(){
		this.loadData();
	}
	loadData(){
		this.props.dispatch(issues_list_request(this.state.filter ? "open" : "all"));
	}

	render(){
		
		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
								<Header as='h3' floated="left" style={{paddingTop:20, paddingLeft: 10}}>
									<Icon name="edit"></Icon>
									<Header.Content>
										{this.props.auth.role !== "customer" ? "All Issues" : "My Issues"}
									</Header.Content>
								</Header>

								
								<Menu compact icon="labeled" secondary size="tiny" floated="right">
								<Issue_Create dispatch={this.props.dispatch}>
									<Menu.Item
										name='Create'
										onClick={() => {this.loadData()}}
									>
										<Icon name="plus" />
										New Issue
									</Menu.Item>
								</Issue_Create>

								<Menu.Item
									name='Refresh'
									onClick={() => {this.loadData()}}
								>
									<Icon name="refresh" />
									Refresh
								</Menu.Item>
							</Menu>
							<Button
								style={{marginTop: 20}}
								size="mini"
								icon 
								labelPosition="left"
								color="blue"
								onClick={() => {this.setState({filter: !this.state.filter}, () =>  this.loadData())}}
								//color={this.state.filter ? "red" : "blue"}
								>
									Displaying: {this.state.filter ? "Open Issues" : "All Issues"}
									<Icon name="filter" />
									
							</Button>						
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{paddingTop: 0}}>
						<Grid.Column>
							<Segment style={{padding: 0}}>
								<Table unstackable attached basic style={{margin: 0}}>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell width={1}>Issue ID</Table.HeaderCell>
											{this.props.auth.role !== "customer" ? (
												<React.Fragment>
													<Table.HeaderCell width={2}>Customer</Table.HeaderCell>		
												</React.Fragment>
											) : null}
											<Table.HeaderCell width={2}>Submitter</Table.HeaderCell>
											<Table.HeaderCell width={4}>Subject</Table.HeaderCell>
											<Table.HeaderCell width={2}>Type</Table.HeaderCell>
											<Table.HeaderCell width={2}>Last Modified Date</Table.HeaderCell>
											<Table.HeaderCell width={2}>Status</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{
										this.props.issues.data.map(x=>{
											return (
											<Table.Row key={x._id}>
												<Table.Cell><Label color="blue" size="medium" as={Link} to={`/issues/${x._id}`}>{x._id.substring(0, 8).toUpperCase()}</Label></Table.Cell>
												{this.props.auth.role !== "customer" ? (
													<React.Fragment>
														<Table.Cell>{x?.customer?.name ?? "Non-Customer"}</Table.Cell>
													</React.Fragment>
												) : null}
												
												<Table.Cell>{x.submitter?.name ?? "Unknown"}</Table.Cell>
												<Table.Cell>{x.subject}</Table.Cell>
												
												<Table.Cell>{x.issue_type}</Table.Cell>
												<Table.Cell>{dateformat(x.timestamp_lastmodify)}</Table.Cell>
												<Table.Cell>{x.isOpen ? "Open" : "Closed"}</Table.Cell>
											</Table.Row>
											)
										})
										}
										{!this.props.issues.loading && this.props.issues.data.length == 0 ? (
											<Table.Row>
												<Table.Cell colSpan={16}>
													There's no data to show
												</Table.Cell>
											</Table.Row>
										) : null}
									</Table.Body>
								</Table>
								{
									this.props.issues.loading ? (
										<Segment secondary attached='bottom'>
										<Icon name='circle notched' loading />
											<Translate value="application.loadingData" />
										</Segment>
									): null 
								}
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	issues: state.issues
})
export default connect(mapStateToProps)(withRouter(Issues))