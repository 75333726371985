import {
	USER_LOGIN_ACTION, 
	USER_LOGIN_FAILURE, 
	USER_LOGIN_SUCCESS,

	USER_LOGOUT_ACTION,
	USER_LOGOUT_SUCCESS,
	USER_LOGOUT_FAILURE,

	USER_REAUTH_ACTION,
	USER_REAUTH_SUCCESS,
	USER_REAUTH_FAILURE,

	USER_REGISTER_ACTION,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAILURE,

	USER_FORGOT_ACTION,
	USER_FORGOT_SUCCESS,
	USER_FORGOT_FAILURE,

	USER_ENROLL_START_ACTION,
	USER_ENROLL_START_SUCCESS,
	USER_ENROLL_START_FAILURE,
	USER_ENROLL_COMPLETE_ACTION, 
	USER_ENROLL_COMPLETE_SUCCESS,
	USER_ENROLL_COMPLETE_FAILURE,
	
	USER_CHANGE_PASSWORD_ACTION,
	USER_CHANGE_PASSWORD_SUCCESS,
	USER_CHANGE_PASSWORD_FAILURE,
	USER_UPDATE_PROFILE_ACTION,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_UPDATE_PROFILE_FAILURE,

	USER_SSO_LOGIN_ACTION,
	USER_SSO_LOGIN_SUCCESS,
	USER_SSO_LOGIN_FAILURE

} from '../actions'

const auth = (state = {token: ""}, action) => {
	switch (action.type) {
		case USER_LOGIN_ACTION:
			return {
				email: action.email,
				status: "pending",
			};
		case USER_LOGIN_FAILURE:
			console.log(action);
			return {
				status: "failed",
				explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
			};
		case USER_LOGIN_SUCCESS: 
			return {
				status: "successful",
				...action.response.data,
				token: action.response.data.token,
				name: action.response.data.name,
				role: action.response.data.role,
				allow_notifications: action.response.data.allow_notifications,
				customer: action.response.data.customer,
				
			}
		// case USER_LOGOUT_ACTION: {
		// 	return {
				
		// 	}
		// }
		case USER_LOGOUT_SUCCESS: {
			return {
				status: "",
				token: "",
				email: ""
			}
		}
		case USER_LOGOUT_FAILURE: {
			return {
				
			}
		}
		case USER_REAUTH_ACTION: {
			return {
				...state,
				reauthing: true
			}
		}
		case USER_REAUTH_SUCCESS: {
			return {
				...state,
				...action.response,
				status: "successful",
				reauthing: null,
				email: action.response.email,
				name: action.response.name,
				role: action.response.role,
				customer: action.response.customer,
				allow_notifications: action.response.allow_notifications
			}
		}
		case USER_REAUTH_FAILURE: {
			return {
				...state,
				reauthing: null,
				status: "failed",
				explanation: "Re-Authing Failed"
			}
		}
		case USER_REGISTER_ACTION: {
			return {
				email: action.email,
				site_id: action.email,
				register_status: "pending",
			};
		}
		case USER_REGISTER_SUCCESS: {
			return {
				email: action.email,
				site_id: action.email,
				register_status: "successful",
			};
		}
		case USER_REGISTER_FAILURE: {
			return {
				email: action.email,
				site_id: action.email,
				register_status: "failed",
				explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
			};
		}

		case USER_FORGOT_ACTION: {
			return {
				email: action.email,
				forgot_status: "pending",
			};
		}
		case USER_FORGOT_SUCCESS: {
			return {
				email: action.email,
				forgot_status: "successful",
			};
		}
		case USER_FORGOT_FAILURE: {
			return {
				email: action.email,
				forgot_status: "failed",
				explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
			};
		}


		case USER_ENROLL_START_ACTION: {
			return {
				...state,
				data: [],
				enroll_loading: true
			};
		}
		case USER_ENROLL_START_SUCCESS: {
			return {
				...state,
				...action.response,
				enroll_loading: false
			};
		}
		case USER_ENROLL_START_FAILURE: {
			return {
				enroll_loading: false,
				explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
			};
		}
		case USER_ENROLL_COMPLETE_ACTION : {
			return {
				...state,
				enrolling: true
			}
		}
		case USER_ENROLL_COMPLETE_SUCCESS: {
			return {
				...state,
				...action.response,
				enrolling: null,
			}
		}
		case USER_ENROLL_COMPLETE_FAILURE: {
			return {
				token: action.token,
				forgot_status: "failed",
				explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
			};
		}


		case USER_CHANGE_PASSWORD_ACTION: {
			return {
				...state,
				password_update_status: "pending",
				password_update_error_explanation: null
			};
		}
		case USER_CHANGE_PASSWORD_SUCCESS: {
			return {
				...state,
				password_update_status: null,
				password_update_error_explanation: null
			};
		}
		case USER_CHANGE_PASSWORD_FAILURE: {
			console.log(state);
			return {
				...state,
				password_update_status: "failed",
				password_update_error_explanation: action.response.data.explanation
			};
		}
		case USER_UPDATE_PROFILE_ACTION: {
			return {
				...state,
				profile_update_status: "pending",
				profile_update_error_explanation: null
			};
		}
		case USER_UPDATE_PROFILE_SUCCESS: {
			return {
				...state,
				profile_update_status: null,
				profile_update_error_explanation: null
			};
		}
		case USER_UPDATE_PROFILE_FAILURE: {
			return {
				...state,
				profile_update_status: "failed",
				profile_update_error_explanation: action.response.data.explanation
			};
		}

		case USER_SSO_LOGIN_ACTION : {
			return {
				...state,
				sso_login_status: "pending",
				sso_login_error_explanation: null
			};
		}

		case USER_SSO_LOGIN_SUCCESS: {
			return {
				status: "successful",
				sso_login_status: "successful",
				sso_login_error_explanation: null,
				...action.response.data,
				token: action.response.data.token,
				name: action.response.data.name,
				role: action.response.data.role,
				allow_notifications: action.response.data.allow_notifications,
				customer: action.response.data.customer,
			}

		}

		case USER_SSO_LOGIN_FAILURE: {
			return {
				...state,
				sso_login_status: "failed",
				sso_login_error_explanation: action.response.data.explanation
			};
		}

		default:{
			return state
		}
			
	}
}

export default auth;
