import {
	LICENSE_FILES_LIST_ACTION,
	LICENSE_FILES_LIST_SUCCESS,
	LICENSE_FILES_LIST_FAILURE,
	LICENSE_FILES_UPDATE_ACTION,
	LICENSE_FILES_UPDATE_SUCCESS,
	LICENSE_FILES_UPDATE_FAILURE,

} from '../actions';
import { createAction } from 'redux-create-action';
import axios from 'axios';
import {store} from '../store';

const license_files_list_set_pending 		= 	createAction(LICENSE_FILES_LIST_ACTION);
const license_files_list_set_successful 	= 		createAction(LICENSE_FILES_LIST_SUCCESS, 'response');
const license_files_list_set_failed 		= 		createAction(LICENSE_FILES_LIST_FAILURE);

const license_files_update_set_pending 		= 		createAction(LICENSE_FILES_UPDATE_ACTION);
const license_files_update_set_successful 	= 		createAction(LICENSE_FILES_UPDATE_SUCCESS, 'response');
const license_files_update_set_failed 		= 		createAction(LICENSE_FILES_UPDATE_FAILURE);

export const license_files_list_request = () => dispatch => {
	dispatch(license_files_list_set_pending());

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/license_files/',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(license_files_list_set_successful(res.data));
		} else {
			dispatch(license_files_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(license_files_list_set_failed());
	});
}

export const license_files_refresh_request = () => dispatch => {

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/license_files/',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(license_files_list_set_successful(res.data));
		} else {
			dispatch(license_files_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(license_files_list_set_failed());
	});
}


export const license_file_create_request = (data) => dispatch => {
	return new Promise((resolve, reject) => {
		let form_data = new FormData();
		for ( var key in data ) {
			form_data.append(key, data[key]);
		}
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/license_files/create', form_data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 201) {
				dispatch(license_files_refresh_request());
				resolve(true);
			} else {
				reject(res);
			}
		})
		.catch((err) => {
			reject(err.response.data);
		});
	});
}

export const license_file_update_request = (file_id, data) => dispatch => {
	dispatch(license_files_update_set_pending());
	return new Promise((resolve, reject) => {
		let form_data = new FormData();
		for ( var key in data ) {
			form_data.append(key, data[key]);
		}
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/license_files/update/' + file_id, form_data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(license_files_update_set_successful(res.data));
				dispatch(license_files_refresh_request());
				resolve(true);
			} else {
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(license_files_update_set_failed());
			reject(err.response.data);
		});
	});
}

export const license_files_delete_request = (file_id) => dispatch => {
	dispatch(license_files_update_set_pending());

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/license_files/delete/' + file_id,{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(license_files_update_set_successful(res));
			dispatch(license_files_refresh_request());
		} else {
			dispatch(license_files_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(license_files_update_set_failed());
	});
}