import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message, Select, Checkbox } from 'semantic-ui-react'
import { Translate, I18n } from "react-redux-i18n";
import { customers_create_request } from 'redux/customers/actions';
import { connect } from 'react-redux';

export default class Customers_Create extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			siteID: "", 
			name: "", 
			active: true,
			// street_address: "", 
			// city: "", 
			// country: "", 
			// contact_name: "", 
			// contact_email: "", 
			// contact_phone: "",
			errorMessage: ""
		}
	}
	
	submitForm(){
		console.log("Submit");
		
		this.setState({errorMessage: null}, () => {
			let {open, errorMessage, ...rest} = this.state;
			this.props.dispatch(customers_create_request(rest))
			.then((resolved , rejected) => {
				if(resolved){
					this.close();
				}
			})
			.catch(err => {
				this.setState({errorMessage : err.message});
			})
		});
	}

	close(){
		this.setState({open: false});
	}
	render(){
		let status = false;
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				closeOnDimmerClick={false} 
			>
				<Header icon='plus' content={"Create Customer"} />
				<Modal.Content>
					<Form onSubmit={() => this.onFormSubmit()}>
						<Form.Input label="Site ID" name="siteID" placeholder='Site ID#' type='text' onChange={(e, data) => this.setState({siteID: data.value})} value={this.state.siteID} />
						<Form.Input label="Name" name="name" placeholder='Name#' type='text' onChange={(e, data) => this.setState({name: data.value})} value={this.state.name} />
						<Form.Field>
							<Checkbox 
								label='Customer Accounts Active' 
								onChange={(e, data) => this.setState({active: data.checked})} 
								checked={this.state.active}
							/>
						</Form.Field>
						{/* <Form.Input label="Street Address" name="street_address" placeholder='Street Address' type='text' onChange={(e, data) => this.setState({street_address: data.value})} value={this.state.street_address} />
						<Form.Input label="City" name="city" placeholder='City' type='text' onChange={(e, data) => this.setState({city: data.value})} value={this.state.city} />
						<Form.Input label="Country" name="country" placeholder='Country' type='text' onChange={(e, data) => this.setState({country: data.value})} value={this.state.country} />
						<Form.Input label="Contact Name" name="contact_name" placeholder='Contact Name' type='text' onChange={(e, data) => this.setState({contact_name: data.value})} value={this.state.contact_name} />
						<Form.Input label="Contact Email" name="contact_email" placeholder='Contact Email' type='text' onChange={(e, data) => this.setState({contact_email: data.value})} value={this.state.contact_email} />
						<Form.Input label="Contact Phone" name="contact_phone" placeholder='Contact Phone' type='text' onChange={(e, data) => this.setState({contact_phone: data.value})} value={this.state.contact_phone} /> */}
					</Form>
					{this.state.errorMessage ? (
						<Message error>
							<Icon name='exclamation' />
							{this.state.errorMessage}
						</Message>
					) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("users_create.cancel")}
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content={I18n.t("users_create.create")}
						color='green'
						icon="checkmark"
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}