import {
	LICENSE_FILES_LIST_ACTION,
	LICENSE_FILES_LIST_SUCCESS,
	LICENSE_FILES_LIST_FAILURE
} from '../actions'

const customers = (state = {data: [], loading: true}, action) => {
	switch (action.type) {
		case LICENSE_FILES_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case LICENSE_FILES_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case LICENSE_FILES_LIST_FAILURE:
			return {
				...state,
				data: [],
				loading: false
			};
		default:{
			return state
		}
			
	}
}

export default customers;
