import {
	USER_LOGIN_ACTION,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAILURE, 

	USER_LOGOUT_ACTION, 
	USER_LOGOUT_SUCCESS, 
	USER_LOGOUT_FAILURE, 
	
	
	USER_REAUTH_ACTION,
	USER_REAUTH_SUCCESS,
	USER_REAUTH_FAILURE,
	USER_REGISTER_ACTION,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAILURE,
	USER_FORGOT_ACTION,
	USER_FORGOT_SUCCESS,
	USER_FORGOT_FAILURE,
	USER_ENROLL_START_ACTION,
	USER_ENROLL_START_SUCCESS,
	USER_ENROLL_START_FAILURE,
	USER_ENROLL_COMPLETE_ACTION, 
	USER_ENROLL_COMPLETE_SUCCESS,
	USER_ENROLL_COMPLETE_FAILURE,
	
	USER_CHANGE_PASSWORD_ACTION,
	USER_CHANGE_PASSWORD_SUCCESS,
	USER_CHANGE_PASSWORD_FAILURE,
	
	USER_UPDATE_PROFILE_ACTION,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_UPDATE_PROFILE_FAILURE,

	USER_SSO_LOGIN_ACTION,
	USER_SSO_LOGIN_SUCCESS,
	USER_SSO_LOGIN_FAILURE

} from '../actions';
import axios from 'axios';
import { error, success } from 'react-notification-system-redux';
import {store} from '../store';
import { createAction } from 'redux-create-action';

const user_login_set_pending 		= createAction(USER_LOGIN_ACTION, 'email');
const user_login_set_successful 	= createAction(USER_LOGIN_SUCCESS, 'email', 'response');
const user_login_set_failed 		= createAction(USER_LOGIN_FAILURE, 'email', 'response');

const user_reauth_set_pending 		= createAction(USER_REAUTH_ACTION, 'token');
const user_reauth_set_successful 	= createAction(USER_REAUTH_SUCCESS, 'token', 'response');
const user_reauth_set_failure 		= createAction(USER_REAUTH_FAILURE);

const user_logout_set_pending 		= createAction(USER_LOGOUT_ACTION);
const user_logout_set_successful	= createAction(USER_LOGOUT_SUCCESS);
const user_logout_set_failed 		= createAction(USER_LOGOUT_FAILURE);

const user_register_set_pending 	= createAction(USER_REGISTER_ACTION, 'email', 'site_id');
const user_register_set_successful 	= createAction(USER_REGISTER_SUCCESS, 'email', 'site_id', 'response');
const user_register_set_failed 		= createAction(USER_REGISTER_FAILURE, 'email', 'site_id', 'response');

const user_forgot_set_pending 		= createAction(USER_FORGOT_ACTION, 'email');
const user_forgot_set_successful 	= createAction(USER_FORGOT_SUCCESS, 'email', 'response');
const user_forgot_set_failed 		= createAction(USER_FORGOT_FAILURE, 'email', 'response');

const user_enroll_start_set_pending = createAction(USER_ENROLL_START_ACTION, 'token')
const user_enroll_start_set_successful = createAction(USER_ENROLL_START_SUCCESS, 'token', 'response')
const user_enroll_start_set_failed  = createAction(USER_ENROLL_START_FAILURE, 'token', 'response')
const user_enroll_complete_set_pending 		= createAction(USER_ENROLL_COMPLETE_ACTION, 'token')
const user_enroll_complete_set_successful 	= createAction(USER_ENROLL_COMPLETE_SUCCESS, 'token', 'response')
const user_enroll_complete_set_failed 		= createAction(USER_ENROLL_COMPLETE_FAILURE, 'token', 'response');


const user_change_password_set_pending 		= createAction(USER_CHANGE_PASSWORD_ACTION, 'token')
const user_change_password_set_successful 	= createAction(USER_CHANGE_PASSWORD_SUCCESS, 'response')
const user_change_password_set_failed 		= createAction(USER_CHANGE_PASSWORD_FAILURE, 'response');

const user_update_profile_set_pending 		= createAction(USER_UPDATE_PROFILE_ACTION, 'token')
const user_update_profile_set_successful 	= createAction(USER_UPDATE_PROFILE_SUCCESS, 'response')
const user_update_profile_set_failed 		= createAction(USER_UPDATE_PROFILE_FAILURE, 'response');


const user_sso_login_set_pending 		= createAction(USER_SSO_LOGIN_ACTION, 'code')
const user_sso_login_set_successful 	= createAction(USER_SSO_LOGIN_SUCCESS, 'response')
const user_sso_login_set_failed 		= createAction(USER_SSO_LOGIN_FAILURE, 'response');


export const get_token = () => {
	return store.getState().auth.token;
}

export const user_login_request = (email, password) => async dispatch => {
	try
	{		
		dispatch(user_login_set_pending(email));
		let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/auth/login', {
			email: email,
			password: password
		});
		
		if(res.status === 200 && res.data.status && res.data.status === "successful"){
			dispatch(user_login_set_successful(email, res));
			dispatch(success({title: "Login Successful", message: "Welcome " + res.data.name}))
		} else if(res.data.status && res.data.status === "failed"){
			console.log(res);
			dispatch(user_login_set_failed(email, res));
		}
	}
	catch(error){
		if (error.response) {
			dispatch(user_login_set_failed(email, {data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_login_set_failed(email, {data: {explanation: error.message}}));
		}	
	}
}

export const user_reauth_request = (token, silent = false) => dispatch => {
	if(store.getState().auth.token && store.getState().auth.token !== ""){

		if(!silent)
			dispatch(user_reauth_set_pending(token));

		axios.get(
			process.env.REACT_APP_SERVER_URL + '/api/auth/verifytoken/', 
			{
				headers: {Authorization: 'Bearer ' + token
			}
		})
			.then(res => {
				if (res.status === 200) {
					dispatch(user_reauth_set_successful(token, res.data));
				}
				else if (res.status === 401) {
					dispatch(user_reauth_set_failure(res));
				}
			})
			.catch((err) => {
				dispatch(user_reauth_set_failure());
				// dispatch(error({title: "Re-Auth Failed", message: "The authorization token your browser has is not valid anymore. This problem usually happens when user logs in from another device. Just try to log-in again.", autoDismiss: 10}));
			});
	}
}

export const user_register_request = (email, name, site_id) => async dispatch => {
	try
	{		
		dispatch(user_register_set_pending(email, site_id));
		let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/auth/register', {
			email,
			name,
			site_id
		});
		
		if(res.status === 200 && res.data.status && res.data.status === "successful"){
			dispatch(user_register_set_successful(email, res));
		} else if(res.data.status && res.data.status === "failed"){
			console.log(res);
			dispatch(user_register_set_failed(email, site_id, res));
		}
	}
	catch(error){
		if (error.response) {
			dispatch(user_register_set_failed(email, site_id, {data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_register_set_failed(email, site_id, {data: {explanation: error.message}}));
		}	
	}
}

export const user_forgot_request = (email) => async dispatch => {
	try
	{		
		dispatch(user_forgot_set_pending(email));
		let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/auth/forgot', {
			email
		});
		
		if(res.status === 200 && res.data.status && res.data.status === "successful"){
			dispatch(user_forgot_set_successful(email, res));
		} else if(res.data.status && res.data.status === "failed"){
			console.log(res);
			dispatch(user_forgot_set_failed(email, res));
		}
	}
	catch(error){
		if (error.response) {
			dispatch(user_forgot_set_failed(email, {data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_forgot_set_failed(email, {data: {explanation: error.message}}));
		}	
	}
}

export const user_logout_request = () => async dispatch => {
	try
	{		
		dispatch(user_logout_set_pending());
		let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/auth/logout', {
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		});
		
		if(res.status === 200 && res.data.logout_status && res.data.logout_status === "successful"){
			dispatch(user_logout_set_successful());
			dispatch(success({title: "Logout Successful", message: "You can now safely close this page"}))
		} else if(res.data.status && res.data.status === "failed"){
			console.log(res);
			dispatch(user_logout_set_failed(res));
		}
	}
	catch(error){
		if (error.response) {
			dispatch(user_logout_set_failed({data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_logout_set_failed({data: {explanation: error.message}}));
		}	
	}
}

export const users_enroll_start_request = (token) => async dispatch => {
	try{
		dispatch(user_enroll_start_set_pending());
		let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/auth/enroll/' + token, {});
		
		if (res.status === 200) {
			dispatch(user_enroll_start_set_successful(token, res.data));
		} else {
			dispatch(user_enroll_start_set_failed(token, res.data));
		}	
	}
	catch(error){
		if (error.response) {
			dispatch(user_enroll_start_set_failed(token, {data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_enroll_start_set_failed(token, {data: {explanation: error.message}}));
		}	
	}
}

export const users_enroll_complete_request = (token, password) => async dispatch => {
	try{
		dispatch(user_enroll_complete_set_pending());
		let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/auth/enroll/', {
			token: token,
			password: password
		});
		
		if (res.status === 200) {
			dispatch(user_enroll_complete_set_successful(token, res.data));
			// dispatch(user_reauth_request(store.getState().auth.token));
		} else {
			dispatch(user_enroll_complete_set_failed(token, res.data));
		}	
	}
	catch(error){
		if (error.response) {
			dispatch(user_enroll_complete_set_failed(token, {data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_enroll_complete_set_failed(token, {data: {explanation: error.message}}));
		}	
	}
}

export const users_change_password_request = (current_password, new_password, new_password2) => async dispatch => {
	try{
		dispatch(user_change_password_set_pending());
		let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/auth/change-password', {
			current_password,
			new_password,
			new_password2
		}, {
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		});
		
		if (res.status === 200) {
			dispatch(user_change_password_set_successful(res.data));
			dispatch(success({title: "Operation Successful", message: "You have successfully changed your password."}))
		} else {
			dispatch(user_change_password_set_failed(res.data));
		}	
	}
	catch(error){
		if (error.response) {
			dispatch(user_change_password_set_failed({data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_change_password_set_failed({data: {explanation: error.message}}));
		}	
	}
}

export const users_update_profile_request = (name, allow_notifications) => async dispatch => {
	try{
		dispatch(user_update_profile_set_pending());
		let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/auth/update-profile', {
			name,
			allow_notifications
		}, {
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		});
		
		if (res.status === 200) {
			dispatch(user_update_profile_set_successful(res.data));
			dispatch(user_reauth_request(store.getState().auth.token, true));
			
			dispatch(success({title: "Operation Successful", message: "You have updated your profile details."}))
		} else {
			dispatch(user_update_profile_set_failed(res.data));
		}	
	}
	catch(error){
		if (error.response) {
			dispatch(user_update_profile_set_failed({data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_update_profile_set_failed({data: {explanation: error.message}}));
		}	
	}
}

export const user_sso_login_request = (data) => async dispatch => {
	try
	{		
		dispatch(user_sso_login_set_pending());
		let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/auth/sso-login', {
			data
		});
		
		if(res.status === 200 && res.data.status && res.data.status === "successful"){
			dispatch(user_sso_login_set_successful(res));
			dispatch(success({title: "Login Successful", message: "Welcome " + res.data.name}))
		} else if(res.data.status && res.data.status === "failed"){
			console.log(res);
			dispatch(user_sso_login_set_failed(res));
		}
	}
	catch(error){
		if (error.response) {
			dispatch(user_sso_login_set_failed({data: {explanation: error.response.data}}));
		
		} else {
			dispatch(user_sso_login_set_failed({data: {explanation: error.message}}));
		}	
	}
}