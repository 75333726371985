import {
	ISSUES_LIST_ACTION,
	ISSUES_LIST_SUCCESS,
	ISSUES_LIST_FAILURE
} from '../actions'

const issues = (state = {data: [], loading: true}, action) => {
	switch (action.type) {
		case ISSUES_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case ISSUES_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case ISSUES_LIST_FAILURE:
			return {
				...state,
				data: [],
				loading: false
			};
		default:{
			return state
		}
			
	}
}

export default issues;
