import React, { Component } from 'react';
import { Message, Item, Grid, Button, Icon, Menu, Segment, Header, List, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Translate, I18n } from "react-redux-i18n";
import { Link, withRouter } from 'react-router-dom';
import Prompt from 'pages/Prompt';

import { customers_list_request } from 'redux/customers/actions';
import { software_releases_list_request, software_releases_delete_request } from 'redux/software_releases/actions';
import ReactMarkdown from 'react-markdown'
import { get_token } from 'redux/auth/actions';
import remarkGfm from 'remark-gfm'
import 'style/markdown.css';
import Release_Create from './Release_Create';
import Release_Modify from './Release_Modify';

class Releases extends Component{
	componentDidMount(){
		this.loadData();
	}
	loadData(){
		this.props.dispatch(software_releases_list_request());
		this.props.dispatch(customers_list_request());	
	}

	componentDidUpdate(oldProps){
		if(this.props.software_releases && this.props.software_releases.data && this.props.software_releases.data[0]){
			if(!this.props.match.params?._id){
				this.props.history.push("/releases/" + this.props.software_releases.data[0]._id);
			}
		}
	}

	getCurrentItemMarkdown() {
		let _id = this.props.match.params?._id;
		if(!_id) return null;

		let item = this.props.software_releases.data.find(x=> x._id == _id);
		if(item){
			return item.release_note;
		}
		else {
			return "Not found";
		}
	}

	getHighlight(item_id){
		let _id = this.props.match.params?._id;
		if(!_id) return null;
		return (_id == item_id)
	}

	getCurrentItemName() {
		let item = this.getCurrentItem();
		if(item && item.software_name && item.release_version){
			return item.software_name + " " + item.release_version;
		}
		else {
			return "Unknown";
		}
	}
	deleteCurrentItem(){
		let _item = this.getCurrentItem();
		if(_item){
			this.props.dispatch(software_releases_delete_request(_item._id));
		}
	}


	getCurrentItem(){
		let _id = this.props.match.params?._id;
		if(!_id) return null;

		return this.props.software_releases.data.find(x=> x._id == _id);	
	}

	formatSizeUnits(bytes){
		if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
		else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
		else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
		else if (bytes > 1)           { bytes = bytes + " bytes"; }
		else if (bytes == 1)          { bytes = bytes + " byte"; }
		else                          { bytes = "0 bytes"; }
		return bytes;
	  }

	getCurrentItemBinaries() {
		let _id = this.props.match.params?._id;
		if(!_id) return null;

		let item = this.props.software_releases.data.find(x=> x._id == _id);
		if(item){
			let data= (
				<List divided relaxed>
					{item.binaries.map(bin => (
						
							<List.Item key={bin._id}>
								<List.Content floated='right'>
								<a 
									target="_blank" 
									href={`${process.env.REACT_APP_SERVER_URL}/api/files/${bin._id}/${get_token()}`}
								>
									<Button size="small" icon labelPosition='left'>
										<Icon name='download'/>
										Download
									</Button>
								</a>
								</List.Content>
								<List.Icon name='paperclip'></List.Icon>
								<List.Content>
									<List.Header>{bin.filename}</List.Header>
									<List.Description>
										Size: {this.formatSizeUnits(bin.size) ?? "Unknown"}<br/>
										MD5: {bin.md5checksum}
									</List.Description>
								</List.Content>
							</List.Item>
						)
					)}
				</List>
			);
			return data;

		}
		else {
			return "Not found";
		}
	}


	render(){
		if(this.props.software_releases.loading){
			return (
				<Dimmer active inverted style={{height: "100vh"}}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			)
		}

		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:20, paddingLeft: 10}}>
							<Icon name="download"></Icon>
							<Header.Content>Software Releases</Header.Content>
						</Header>
						</Grid.Column>
						<Grid.Column>
						<Menu compact icon="labeled" secondary size="tiny" floated="right">
							{this.props.auth.role !== "customer" ? (
								<React.Fragment>
									<Release_Create dispatch={this.props.dispatch}>
										<Menu.Item
											name='Create'
										>
											<Icon name="plus" />
											New File
										</Menu.Item>
									</Release_Create>

									<Release_Modify dispatch={this.props.dispatch} data={this.getCurrentItem()}>
										<Menu.Item
											name='edit'
										>
											<Icon name="edit" />
											Edit File
										</Menu.Item>
									</Release_Modify>

									<Prompt
										icon="red delete"
										action={() => this.deleteCurrentItem()} 
										title={I18n.t("release.remove_title")}
										text={I18n.t("release.remove_text", {filename: this.getCurrentItemName()})}>
										<Menu.Item
											name='delete'
										>
											<Icon name="edit" />
											Remove File
										</Menu.Item>
									</Prompt>
								</React.Fragment>
							) : null}

								<Menu.Item
									name='Refresh'
									onClick={() => {this.loadData()}}
								>
									<Icon name="refresh" />
									Refresh
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{
						paddingTop: 0, 
						paddingLeft:0, 
						paddingRight: 12}}>
						<Grid.Column width={4} style={{
							paddingRight: 0,
						}}>
							<Menu fluid vertical pointing attached style={{
								"height" : window.innerHeight - 95
							}}>
								{
									this.props.software_releases.data.map(item=>{
										return (
											<Menu.Item
												as={Link}
												to={"/releases/" + item._id}
												key={item._id}
												active={this.getHighlight(item._id)}
											>
												<Item.Header>{item.software_name ?? "Unkonwn Software"}</Item.Header>
												<Item.Description>{item.release_version ?? "Unkonwn Version"}</Item.Description>
												
											</Menu.Item>
										);
									})
								}
							</Menu>
							
						</Grid.Column>

						<Grid.Column width={12} style={{
							"backgroundColor": "white", 
							"border" : "1px solid #ddd", 
							"borderLeft": "none", 
							"paddingLeft" : 0,
							"overflowY" : "auto",
							"height" : window.innerHeight - 95
						}}>
							
							<Segment basic className="markdown-body">
								<Header dividing size="medium">Downloadable Binaries</Header>
								<Message>
									{this.getCurrentItemBinaries()}
								</Message>

								<Header size="medium" dividing>Release Content</Header>
								<ReactMarkdown remarkPlugins={[remarkGfm]}>{this.getCurrentItemMarkdown()}</ReactMarkdown>
								

								
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	software_releases: state.software_releases,
	customers: state.customers,
	auth: state.auth
})
export default connect(mapStateToProps)(withRouter(Releases))