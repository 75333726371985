import React, { Component } from 'react';
import { Segment, Button, Form, Image, Menu, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { user_sso_login_request } from 'redux/auth/actions';
import { connect } from 'react-redux'
import { css, StyleSheet } from 'aphrodite';
import { Link, Redirect } from 'react-router-dom'

class SingleSignOn extends Component{
	constructor(props) {
		super(props);
	}

	componentDidMount(){
		setTimeout(() => this.props.dispatch(user_sso_login_request(this.props.location?.search ??  "")), 500);
	}


	render(){
		let {status, sso_login_status, sso_login_error_explanation} = this.props.auth;

		if(status === "successful" || sso_login_status === "successful"){
			if(this.props.location.state?.from){
				return (<Redirect replace to={this.props.location.state?.from} />)	
			}
			return (<Redirect replace to="/releases" />)
		}

		return (
			<React.Fragment>
				<Grid textAlign='center' style={{ height: this.props.height ?? '100vh' }} verticalAlign='middle'>
					<Grid.Column style={{ maxWidth: 450 }}>
					<Header as='h2' color='black' textAlign='center'>
							SSO Login
					</Header>
					{ sso_login_status !== "failed" ? (
						<Message icon style={{textAlign:"left"}}>
							<Icon name='circle notched' loading />
							<Message.Content>
							<Message.Header>SSO Sign In</Message.Header>
							Please wait!
							</Message.Content>
						</Message>
					) : null }

					{ sso_login_status === "failed" ? (
						<Message error>
							<Icon name='exclamation' />
							{sso_login_error_explanation}
						</Message>
					) : null}

					<Message>
						Have an issue? <Link to='/'>Start over</Link>
					</Message>

					</Grid.Column>
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
})
export default connect(mapStateToProps)(SingleSignOn)