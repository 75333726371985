import {
	CUSTOMERS_LIST_ACTION,
	CUSTOMERS_LIST_SUCCESS,
	CUSTOMERS_LIST_FAILURE,
	CUSTOMERS_CREATE_ACTION,
	CUSTOMERS_CREATE_SUCCESS,
	CUSTOMERS_CREATE_FAILURE,
	CUSTOMERS_UPDATE_ACTION,
	CUSTOMERS_UPDATE_SUCCESS,
	CUSTOMERS_UPDATE_FAILURE
	

} from '../actions';
import { createAction } from 'redux-create-action';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';



const customers_list_set_pending 		= 		createAction(CUSTOMERS_LIST_ACTION);
const customers_list_set_successful 	= 		createAction(CUSTOMERS_LIST_SUCCESS, 'response');
const customers_list_set_failed 		= 		createAction(CUSTOMERS_LIST_FAILURE);

const customers_create_set_pending 		= 		createAction(CUSTOMERS_CREATE_ACTION);
const customers_create_set_successful 	= 		createAction(CUSTOMERS_CREATE_SUCCESS, 'response');
const customers_create_set_failed 		= 		createAction(CUSTOMERS_CREATE_FAILURE);


export const customers_list_request = () => dispatch => {
	dispatch(customers_list_set_pending());

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/customers/',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(customers_list_set_successful(res.data));
		} else {
			dispatch(customers_list_set_failed());
			dispatch(user_reauth_request());
		}
	})
	.catch((err) => {
		dispatch(customers_list_set_failed());
		dispatch(user_reauth_request());
	});
}

export const customers_list_refresh_request = () => dispatch => {

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/customers/',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(customers_list_set_successful(res.data));
		} else {
			dispatch(customers_list_set_failed());
			dispatch(user_reauth_request());
		}
	})
	.catch((err) => {
		dispatch(customers_list_set_failed());
		dispatch(user_reauth_request());
	});
}

export const customers_create_request = (data) => dispatch => {
	dispatch(customers_create_set_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/customers/create', data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 201) {
				dispatch(customers_create_set_successful(res.data));
				dispatch(customers_list_refresh_request());
				resolve(true);
			} else {
				dispatch(customers_create_set_failed());
				dispatch(user_reauth_request());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(customers_create_set_failed());
			dispatch(user_reauth_request());
			reject(err.response.data);
		});
	});
}


export const customers_update_set_pending = () => ({
	type: CUSTOMERS_UPDATE_ACTION
});

export const customers_update_set_successful = (res) => ({
	type: CUSTOMERS_UPDATE_SUCCESS,
	response: res
});

export const customers_update_set_failed = () => ({
	type: CUSTOMERS_UPDATE_FAILURE
});

export const customers_update_request = (userId, data) => dispatch => {
	dispatch(customers_update_set_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/customers/update/' + userId, data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(customers_update_set_successful(res.data));
				dispatch(customers_list_refresh_request());
				resolve(true);
			} else {
				dispatch(customers_update_set_failed());
				dispatch(user_reauth_request());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(customers_update_set_failed());
			dispatch(user_reauth_request());
			reject(err.response.data);
		});
	});
}


export const customers_delete_request = (customer_id) => dispatch => {
	dispatch(customers_update_set_pending());

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/customers/delete/' + customer_id,{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(customers_update_set_successful(res.data));
			dispatch(customers_list_refresh_request());
		} else {
			dispatch(customers_update_set_failed());
			dispatch(user_reauth_request());
		}
	})
	.catch((err) => {
		dispatch(customers_update_set_failed());
		dispatch(user_reauth_request());
	});
}