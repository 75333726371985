import React, { Component } from 'react';
import { Segment, Button, Form, Image, Menu, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { user_logout_request } from 'redux/auth/actions';
import { connect } from 'react-redux'
import { css, StyleSheet } from 'aphrodite';
import { Link, Redirect } from 'react-router-dom'

class Logout extends Component{
	constructor(props) {
		super(props);
	}

	componentDidMount(){
		setTimeout(() => this.props.dispatch(user_logout_request()), 500);
	}

	render(){
		let {status} = this.props.auth;

		if(status !== "successful"){
			return (<Redirect replace to="/" />)
		}

		return (
			<React.Fragment>
				<Grid textAlign='center' style={{ height: this.props.height ?? '100vh' }} verticalAlign='middle'>
					<Grid.Column style={{ maxWidth: 450 }}>
						<Message icon style={{textAlign:"left"}}>
							<Icon name='circle notched' loading />
							<Message.Content>
							<Message.Header>Logging Out</Message.Header>
								Please wait!
							</Message.Content>
						</Message>
					</Grid.Column>
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
})
export default connect(mapStateToProps)(Logout)