import { Router, Route, Redirect } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import SideMenu from '../components/SideMenu';

export function PrivateRoute({ children, ...rest }) {
	const auth = useSelector((state) => state.auth);
	return (
		<Route
			{...rest}
			render={({ location }) => {
				if(auth.status === "successful") {
					return (
						<SideMenu>
							{children}
						</SideMenu>
					)
				} else {
					return (
						<Redirect to={{
							pathname: "/login",
							state: { from: location }
						}} />
					)
				}
			}}
		/>
	);
}