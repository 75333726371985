import { combineReducers } from 'redux'

import {reducer as notifications} from 'react-notification-system-redux';
import { i18nReducer } from 'react-redux-i18n';
import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session';

import auth from './auth/reducer';
import app from './app/reducer';
import customers from './customers/reducer';
import users from './users/reducer';
import software_releases from './software_releases/reducer';
import license_files from './license_files/reducer';
import issues from './issues/reducer';
import issue_view from './issue_view/reducer';



const authPersistConfig = {
	key: 'auth',
	storage: storageSession,
	whitelist: ['token']
}

const appPersistConfig = {
	key: 'app',
	storage: storageSession,
	whitelist: ['sidebar']
  }

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, app),
  auth: persistReducer(authPersistConfig, auth),
  users,
  notifications,
  i18n: i18nReducer,
  customers,
  software_releases,
  license_files,
  issues,
  issue_view
});

export default rootReducer;

