import React, { Component } from 'react';
import { Segment, Button, Form, Image, Menu, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { users_enroll_start_request, users_enroll_complete_request } from 'redux/auth/actions';
import { connect } from 'react-redux'
import { css, StyleSheet } from 'aphrodite';
import { Link, Redirect, withRouter } from 'react-router-dom'

class Enroll extends Component{
	constructor(props) {
		super(props);
		this.state={
			password: "",
			password2: "",
			form_error: false
		}

	}

	componentDidMount(){
		this.loadData();
	}

	loadData(){
		if(this.props.hash && this.props.hash.length > 0 && (!this.props.auth.status || this.props.auth.status !== "successful")){
			let token = this.props.hash.substring(1);
			this.props.dispatch(users_enroll_start_request(token));
		}
	}

	onFormSubmit(){
		if(this.props.hash && this.props.hash.length > 0){
			let token = this.props.hash.substring(1);
			this.props.dispatch(users_enroll_complete_request(token, this.state.password));
		}
	}

	handleChange(e, data){
		switch(data.name){
			case "email": this.setState({email: data.value}); break;
			case "password": {
				this.setState({password: data.value});
				
			} break;
			case "password2": {
				this.setState({password2: data.value});
			} break;
			default: {}
		}
	}

	render(){
		let {enroll_status, enroller_name, explanation, status} = this.props.auth;

		if(status && status === "successful"){
			if(this.props.location.state?.from){
				return (<Redirect replace to={this.props.location.state?.from} />)	
			}
			return (<Redirect replace to="/releases" />)
		}
		return (
			<React.Fragment>
				<Grid textAlign='center' style={{ height: this.props.height ?? '100vh' }} verticalAlign='middle'>
					<Grid.Column style={{ maxWidth: 450 }}>
						<Header as='h2' color='black' textAlign='center'>
							{enroll_status ? "Welcome " + enroller_name : "Error"}!
						</Header>
						{enroll_status ? (
							<Form onSubmit={() => this.onFormSubmit()}>
								<Segment stacked>
										<p>
											Specify your password for access.
										</p>
									<Form.Input
										fluid
										icon='lock'
										iconPosition='left'
										placeholder='Password'
										type='password'
										name="password"
										onChange={(e, data) => this.handleChange(e, data)} 
										value={this.state.password}
										error={this.state.form_error != false}
										required
									/>
									<Form.Input
										fluid
										icon='lock'
										iconPosition='left'
										placeholder='Password (again)'
										type='password'
										name="password2"
										onChange={(e, data) => this.handleChange(e, data)} 
										value={this.state.password2}
										error={this.state.form_error != false}
										required
									/>
									{/* {this.state.form_error ? ( */}
										<Message error>
											<Icon name='exclamation' />
											{this.state.form_error}
										</Message>
									{/* ): null} */}

									<Button disabled={this.state.form_error} loading={enroll_status === "pending"} color='blue' fluid size='large'>
										{this.state.form_error ? this.state.form_error : "Set Password"}
									</Button>
								</Segment>
							</Form>
						) : (
							<Message error>
								<Icon name='exclamation' />
								{explanation}
							</Message>
						) }

						<Message>
							Proceed to <Link to="/login">Log In</Link>
						</Message>
					</Grid.Column>
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
})
export default connect(mapStateToProps)(withRouter(Enroll))