import {
	CUSTOMERS_LIST_ACTION,
	CUSTOMERS_LIST_SUCCESS,
	CUSTOMERS_LIST_FAILURE
} from '../actions'

const customers = (state = {data: [], loading: false}, action) => {
	switch (action.type) {
		case CUSTOMERS_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case CUSTOMERS_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case CUSTOMERS_LIST_FAILURE:
			return {
				...state,
				data: [],
				loading: false
			};
		default:{
			return state
		}
			
	}
}

export default customers;
