import {
	ISSUES_LIST_ACTION,
	ISSUES_LIST_SUCCESS,
	ISSUES_LIST_FAILURE,
	ISSUE_LOAD_ACTION,
	ISSUE_LOAD_SUCCESS,
	ISSUE_LOAD_FAILURE

} from '../actions';
import { createAction } from 'redux-create-action';
import axios from 'axios';
import {store} from '../store';
import { error, success } from 'react-notification-system-redux';

const issues_list_set_pending 		= 	createAction(ISSUES_LIST_ACTION);
const issues_list_set_successful 	= 	createAction(ISSUES_LIST_SUCCESS, 'response');
const issues_list_set_failed 		= 	createAction(ISSUES_LIST_FAILURE);

const issue_load_set_pending 		= 	createAction(ISSUE_LOAD_ACTION);
const issue_load_set_successful 	= 	createAction(ISSUE_LOAD_SUCCESS, 'response');
const issue_load_set_failed 		= 	createAction(ISSUE_LOAD_FAILURE);

export const issues_list_request = (filter) => dispatch => {
	dispatch(issues_list_set_pending());

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/issues/?filter=' + filter,{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(issues_list_set_successful(res.data));
		} else {
			dispatch(issues_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(issues_list_set_failed());
	});
}

export const issue_load_request = (issue_id) => dispatch => {
	dispatch(issue_load_set_pending());

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/issues/' + issue_id,{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(issue_load_set_successful(res.data));
		} else {
			dispatch(issue_load_set_failed());
		}
	})
	.catch((err) => {
		dispatch(issue_load_set_failed());
	});
}

export const issue_reload_request = (issue_id) => dispatch => {

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/issues/' + issue_id,{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(issue_load_set_successful(res.data));
		} else {
			dispatch(issue_load_set_failed());
		}
	})
	.catch((err) => {
		dispatch(issue_load_set_failed());
	});
}

export const issue_create_request = (data) => dispatch => {
	return new Promise((resolve, reject) => {
		let form_data = new FormData();
		for ( var key in data ) {
			form_data.append(key, data[key]);
		}
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/issues/create', form_data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 201) {
				dispatch(issues_list_request());
				dispatch(success({title: "Operation Successful", message: "New issue has been created!"}))
				resolve(true);
			} else {
				reject({message: res});
			}
		})
		.catch((err) => {
			if(err.response){
				reject({message: err.response.data});
			} else {
				reject({message: err.message});
			}
		});
	});
}

export const issue_comment_request = (issue_id, data) => dispatch => {
	return new Promise((resolve, reject) => {
		let form_data = new FormData();
		for ( var key in data ) {
			form_data.append(key, data[key]);
		}
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/issues/comment/' + issue_id, form_data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 201) {
				dispatch(issue_reload_request(issue_id));
				resolve(true);
			} else {
				reject(res);
			}
		})
		.catch((err) => {
			reject(err.response.data);
		});
	});
}

export const issue_set_status_request = (issue_id, next_status) => dispatch => {
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/issues/set_status/' + issue_id, 
		{next_status
		},{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(issue_reload_request(issue_id));
				resolve(true);
			} else {
				reject(res);
			}
		})
		.catch((err) => {
			reject(err.response.data);
		});
	});
}