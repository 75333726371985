import React, { Component } from 'react';
import { Breadcrumb, Comment, Grid, Divider, Icon, Menu, Segment, Header, Form, Button, Label, Message, Dropdown, Dimmer, Loader} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { Link, NavLink, Route, withRouter, Redirect } from 'react-router-dom'

import Prompt from 'pages/Prompt';
import { Translate, I18n } from "react-redux-i18n";
import { issue_load_request, issue_comment_request, issue_set_status_request } from 'redux/issues/actions';
import Issue_Create from './Issue_Create';
import dateformat from 'tools/dateformat';
import { get_token } from 'redux/auth/actions';

class Issue_View extends Component{
	constructor(props){
		super(props);

		this.state = {
			message: "",
			attachment: null,
			errorMessage: ""
		}
		
		
	}
	componentWillMount(){
		this.loadData();
	}

	loadData(){
		console.log("Loading_Data")
		let issue_id = this.props.match?.params._id;
		this.props.dispatch(issue_load_request(issue_id));
	}

	sendComment(){
		let issue_id = this.props.match.params._id;
		
		this.setState({errorMessage: null}, () => {
			let {errorMessage, ...rest} = this.state;
			this.props.dispatch(issue_comment_request(issue_id, rest))
			.then((resolved , rejected) => {
				if(resolved){
					//this.close();
					// alert("Comment has been created");
					this.setState({message: "", attachment: null, errorMessage: ""});
				}
			})
			.catch(err => {
				this.setState({errorMessage : err.message});
			})
		});
	}

	setIssueStatus(next_status){
		console.log("setIssueStatus");
		let issue_id = this.props.match.params?._id;
		this.props.dispatch(issue_set_status_request(issue_id, next_status));
		
	}

	getStatusColor(status){
		switch(status){
			case "Reopened": return "red";
			case "Submitted": return "orange";
			case "Active": return "blue";
			case "Resolved": return "teal";
			case "Closed": return "green";
		}
	}

	render(){
		let issue = null;

		if(this.props.issue_view.loading){			
			return (
				<Dimmer active inverted style={{height: "100vh"}}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			)
		}
		
		if(this.props.issue_view && this.props.issue_view.data && this.props.issue_view.data)
			issue = this.props.issue_view.data;

		if(!issue)
			return (<Redirect replace to="/issues" />)

		return (
			<React.Fragment>
				<Grid style={{"white-space": "pre-line"}}>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:18, paddingLeft: 10}}>
							<Icon name="edit"></Icon>
							<Header.Content>
							<Breadcrumb size="big">
								<Breadcrumb.Section><Link to="/issues">My Issues</Link></Breadcrumb.Section>
								<Breadcrumb.Divider icon='right chevron' />
								<Breadcrumb.Section>Issue #{issue?._id.substring(0,8).toUpperCase()}</Breadcrumb.Section>
							</Breadcrumb>
							</Header.Content>
						</Header>
						</Grid.Column>
						<Grid.Column>
							<Menu compact icon="labeled" secondary size="tiny" floated="right">
								{issue?.isOpen ? (
								<Menu.Item
									name='Refresh'
									onClick={() => {this.setIssueStatus("closed")}}
								>
									<Icon name="check" />
									Close Issue
								</Menu.Item>
							) : (
								<Menu.Item
									name='Refresh'
									onClick={() => {this.setIssueStatus("open")}}
								>
									<Icon name="exclamation" />
									Reopen Issue
								</Menu.Item>
							)}

								<Menu.Item
									name='Refresh'
									onClick={() => {this.loadData()}}
								>
									<Icon name="refresh" />
									Refresh
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{paddingTop: 0}}>
						<Grid.Column width={16}>
							<Header as="h4" attached="top">Issue Details</Header>
							<Segment attached="bottom">
								<Grid>
									<Grid.Row>
										<Grid.Column width={6}>
											<Header sub>Date/Time Opened</Header>
											<span>{dateformat(issue?.timestamp_create) ?? ""}</span>

											<Header sub>Last Modified Date</Header>
											<span>{dateformat(issue?.timestamp_lastmodify) ?? ""}</span>

											<Header sub>Issue Type</Header>
											<span>{issue?.issue_type ?? ""}</span>
										</Grid.Column>
										<Grid.Column width={6}>
											<Header sub>Product/Version</Header>
											<span>{issue?.software_name ?? ""}/{issue?.software_version ?? ""}</span>

											<Header sub>Customer</Header>
											<span>{issue?.customer?.name ?? "Not specified"}</span>

											<Header sub>Submitter</Header>
											<span>{issue?.submitter?.name ?? "Not specified"}</span>
										</Grid.Column>

										<Grid.Column width={3}>
											<Header sub>Priority</Header>
											<span><Label basic color={issue?.issue_priority == "Critical" ? "red" : "teal"}>{issue?.issue_priority ?? ""}</Label></span>

											<Header sub>Status</Header>
											<span><Label basic color={issue?.isOpen ? "orange" : "green"}>{issue?.isOpen ? "Open" : "Closed"}</Label></span>
										</Grid.Column>
									</Grid.Row>
									<Divider/>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header sub>Subject</Header>
											<span>{issue?.subject ?? ""}</span>

											<Header sub>Description</Header>
											<span>{issue?.description ?? ""}</span>

											
											{issue?.attachment ? (
											<a 
												target="_blank" 
												href={`${process.env.REACT_APP_SERVER_URL}/api/files/${issue?.attachment._id}/${get_token()}`}
											>
												<React.Fragment>
													<Header sub>Attachment</Header>
													
													<Button icon labelPosition='left' size="tiny">
														<Icon name="paperclip" />
														{issue?.attachment.filename}
													</Button>											
												</React.Fragment>
											</a>
											) : null}

											
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
							<Header as="h4" attached="top">Comments</Header>
							<Segment attached="bottom">
								<Comment.Group threaded>
									{!issue?.comments ? "" : issue?.comments.map(comment => (
										<Comment key={comment._id}>
											<Comment.Avatar src='/images/avatar.svg' />
											<Comment.Content>
											<Comment.Author as='a'>{comment.sender.name}</Comment.Author>
											<Comment.Metadata>
												<span>{dateformat(comment.timestamp)}</span>
											</Comment.Metadata>
											<Comment.Text>{comment.message}</Comment.Text>
											<Comment.Actions>
											{comment.attachment ? (
											<a 
												target="_blank" 
												href={`${process.env.REACT_APP_SERVER_URL}/api/files/${comment.attachment._id}/${get_token()}`}
											>
												<Button icon labelPosition='left' size="tiny">
													<Icon name='paperclip' />
													{comment.attachment.filename}
												</Button>
												
											</a>
											) : null}
											</Comment.Actions>
											</Comment.Content>
									  	
										  {/* <Comment.Group threaded>
											{comment.comments ? comment.comments.map(_comment => (
												<Comment key={_comment._id}>
												<Comment.Avatar src='/images/avatar.svg' />
												<Comment.Content>
												<Comment.Author as='a'>{_comment.sender.name}</Comment.Author>
												<Comment.Metadata>
													<span>{dateformat(_comment.timestamp)}</span>
												</Comment.Metadata>
												<Comment.Text>{_comment.message}</Comment.Text>
												<Comment.Actions>
													<a>Reply</a>
												</Comment.Actions>
												</Comment.Content>
											</Comment>
											)) : null}
										</Comment.Group> */}
										</Comment>
									))}
									<Comment key="reply">
										<Comment.Avatar src='/images/avatar.svg' />
										<Comment.Content>
											<Comment.Author as='a'>{this.props.auth.name}</Comment.Author>
											<Form 
												onSubmit={() => this.sendComment()}
												reply 
												style={{marginTop: 0, paddingTop: 5}}>
												<Form.TextArea 
													name="message"
													value={this.state.message}
													onChange={(e, data) => {this.setState({message : data.value})}}
													style={{clear: "unset"}} 
												/>
												<Form.Input
													type="file"
													name="attachment"
													onChange={(e, data) => this.setState({attachment: e.target.files[0]})} 	
												/>
												<Button content='Submit' labelPosition='left' icon='edit' primary />
												
												{this.state.errorMessage ? (
													<Message error>
														<Icon name='exclamation' />
														{this.state.errorMessage}
													</Message>
												) : null}
											</Form>
										</Comment.Content>
									</Comment>
								</Comment.Group>
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid>				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	issue_view: state.issue_view,
	auth: state.auth
})
export default connect(mapStateToProps)(withRouter(Issue_View))