import React, { Component } from 'react';
import { Message, Table, Grid, Button, Icon, Menu, Segment, Header, List, Item, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
import remarkGfm from 'remark-gfm'
import 'style/markdown.css';
import Prompt from 'pages/Prompt';

import ReactMarkdown from 'react-markdown'
import { get_token } from 'redux/auth/actions';
import { license_files_list_request, license_files_delete_request } from 'redux/license_files/actions';
import { customers_list_request } from '../../redux/customers/actions';
import License_Files_Create from './License_Files.Create';
import License_Files_Modify from './License_Files.Modify';

class License_Files extends Component{
	componentDidMount(){
		this.loadData();
	}
	loadData(){
		this.props.dispatch(license_files_list_request());
		this.props.dispatch(customers_list_request());
	}

	componentDidUpdate(oldProps){
		if(this.props.license_files && this.props.license_files.data && this.props.license_files.data[0]){
			if(!this.props.match.params?._id){
				this.props.history.push("/licenses/" + this.props.license_files.data[0]._id);
			}
		}
	}

	getCurrentItemMarkdown() {
		let item = this.getCurrentItem();
		if(item){
			return item.content;
		}
		else {
			return "Not found";
		}
	}

	getCurrentItemFileName() {
		let item = this.getCurrentItem();
		if(item && item.file && item.file.filename){
			return item.file.filename;
		}
		else {
			return "Unknown";
		}
	}

	getCurrentItem() {
		let _id = this.props.match.params?._id;
		if(!_id) return null;

		return this.props.license_files.data.find(x=> x._id == _id);
	}

	deleteCurrentItem(){
		let _item = this.getCurrentItem();
		if(_item){
			this.props.dispatch(license_files_delete_request(_item._id));
		}
	}

	getHighlight(item_id){
		let _id = this.props.match.params?._id;
		if(!_id) return null;
		return (_id == item_id)
	}

	getCurrentItemBinaries() {
		let item = this.getCurrentItem();
		if(item && item.file){
			let data = (
				<List divided relaxed>
					<List.Item key={item._id}>
						<List.Content floated='right'>
						<a 
							target="_blank" 
							href={`${process.env.REACT_APP_SERVER_URL}/api/files/${item.file._id}/${get_token()}`}
						>
							<Button size="small" icon labelPosition='left'>
								<Icon name='download'/>
								Download
							</Button>
						</a>
						</List.Content>
						<List.Icon name='paperclip'></List.Icon>
						<List.Content>
							<List.Header>{item.file.filename}</List.Header>
							<List.Description>MD5: {item.file.md5checksum}</List.Description>
						</List.Content>
					</List.Item>
				</List>
			);
			return data;
		}
		else {
			return "Not found";
		}
	}


	render(){

		if(this.props.license_files.loading){
			return (
				<Dimmer active inverted style={{height: "100vh"}}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			)
		}

		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:20, paddingLeft: 10}}>
							<Icon name="download"></Icon>
							<Header.Content>My Licenses</Header.Content>
						</Header>
						</Grid.Column>
						<Grid.Column>
							<Menu compact icon="labeled" secondary size="tiny" floated="right">
							{this.props.auth.role !== "customer" ? (
								<React.Fragment>
									<License_Files_Create dispatch={this.props.dispatch}>
										<Menu.Item
											name='Create'
										>
											<Icon name="plus" />
											New File
										</Menu.Item>
									</License_Files_Create>

									<License_Files_Modify dispatch={this.props.dispatch} data={this.getCurrentItem()}>
										<Menu.Item
											name='edit'
										>
											<Icon name="edit" />
											Edit File
										</Menu.Item>
									</License_Files_Modify>

									<Prompt
										icon="red delete"
										action={() => this.deleteCurrentItem()} 
										title={I18n.t("license_file.remove_title")}
										text={I18n.t("license_file.remove_text", {filename: this.getCurrentItemFileName()})}>
										<Menu.Item
											name='delete'
										>
											<Icon name="edit" />
											Remove File
										</Menu.Item>
									</Prompt>
								</React.Fragment>
							) : null}

								<Menu.Item
									name='Refresh'
									onClick={() => {this.loadData()}}
								>
									<Icon name="refresh" />
									Refresh
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{
						paddingTop: 0, 
						paddingLeft:0, 
						paddingRight: 12}}>
						<Grid.Column width={4} style={{
							paddingRight: 0,
						}}>
							<Menu fluid vertical pointing attached style={{
								"height" : window.innerHeight - 95
							}}>
								{
									this.props.license_files.data.map(file => {
										return (
											<Menu.Item
												as={Link}
												key={file._id}
												to={"/licenses/" + file._id}
												active={this.getHighlight(file._id)}
											>
												<Item.Header>{file.customer?.name ?? "Unknown Customer"}</Item.Header>
												<Item.Description>{file?.file?.filename ?? "Unknown"}</Item.Description>
												
											</Menu.Item>
										);
									})
								}
								{
									this.props.license_files.loading ? (
										<Menu.Item>
											<Item.Header><Icon name='circle notched' loading /><Translate value="application.loadingData" /></Item.Header>
											<Item.Description></Item.Description>
										</Menu.Item>	
									): null 
								}
							</Menu>
							
						</Grid.Column>

						<Grid.Column width={12} style={{
							"backgroundColor": "white", 
							"border" : "1px solid #ddd", 
							"borderLeft": "none", 
							"paddingLeft" : 0,
							"overflowY" : "auto",
							"height" : window.innerHeight - 95
						}}>
							
							<Segment basic className="markdown-body">
								<Header size="medium" dividing>License File</Header>
								<Message>
									{this.getCurrentItemBinaries()}
								</Message>

								<Header size="medium" dividing>File Content</Header>
								<ReactMarkdown remarkPlugins={[remarkGfm]}>{this.getCurrentItemMarkdown()}</ReactMarkdown>
								
								

								
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>				
				{
					this.props.license_files.loading ? (
						<Segment secondary attached='bottom'>
						<Icon name='circle notched' loading />
							<Translate value="application.loadingData" />
						</Segment>
					): null 
				}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	license_files: state.license_files,
	auth: state.auth
})
export default connect(mapStateToProps)(withRouter(License_Files))