export const en = {
	action: {
		"yes": "Yes",
		"no": "No",
		"ok": "Ok"
	},
	application: {
		title: 'Milbitt Customer Portal',
		langButton: "Language",
		logoutButton: "Log Out",
		loadingData: "Loading",
		reauthing: "Refreshing Authentication"
	},
	users: {
		header: "Users",
		refreshButton: "Refresh",
		createUser: "New User",
		editUser: "Edit User",
		deleteUser: "Kullanıcıyı Sil",
		
		
		remove_title: "Remove User",
		remove_text: "<strong>%{user}</strong> will be deleted. Continue?"
	},
	user: {
		name: "Name",
		email: "E-Mail Address",
		role: "Role",
		customer: "Customer",
		basic_profile_title: "Basic Profile",
		receive_notification: "Receive Notifications",
		update_profile: "Update Profile",
		change_password_title: "Change Password",
		current_password: "Current Password",
		new_password: "New Password",
		new_password2: "New Password (again)",
		change_password_button: "Change Password",
		change_password_error_title: "Some requirements for passwords are not met!",
		change_password_successful_title: "You have successfully changed your password",
		update_profile_error_title: "Error!",
		active: "Account is active",
		notes: "Notes"
	},
	issues: {
		create_issue: "Create Issue",
		cancel: "Cancel",
	}
};
