// @flow

import {
	APP_SIDEBAR_TOGGLE,
	APP_LOAD_CONFIGS_ACTION,
	APP_LOAD_CONFIGS_SUCCESS,
	APP_LOAD_CONFIGS_FAILURE
} from '../actions';

import { createAction } from 'redux-create-action';
import axios from 'axios';

const app_sidebar_toggle = createAction(APP_SIDEBAR_TOGGLE);

const app_load_configs_set_pending 		= createAction(APP_LOAD_CONFIGS_ACTION);
const app_load_configs_set_successful	= createAction(APP_LOAD_CONFIGS_SUCCESS, "response");
const app_load_configs_set_failed 		= createAction(APP_LOAD_CONFIGS_FAILURE, "response");

export const app_load_configs = () => async dispatch => {
	try
	{		
		dispatch(app_load_configs_set_pending());
		let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/configs');
		if(res.status === 200){
			dispatch(app_load_configs_set_successful(res));
		} else {
			dispatch(app_load_configs_set_failed(res));
		}
	}
	catch(error){
		if (error.response) {
			dispatch(app_load_configs_set_failed({data: {explanation: error.response.data}}));
		
		} else {
			dispatch(app_load_configs_set_failed({data: {explanation: error.message}}));
		}	
	}
}