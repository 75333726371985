import React, { Component } from 'react';
import { Segment, Button, Form, Image, Menu, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { css, StyleSheet } from 'aphrodite';
import MediaQuery from 'react-responsive';

import Login from './Login';
import Register from './Register';
import Forgot from './Forgot';
import Enroll from './Enroll';
import SingleSignOn from './SingleSignOn';
import Logout from './Logout';

export default class Welcome extends Component{
	constructor(props) {
		super(props);
	}

	render(){
		
		let activePage = this.props.location.pathname;

		return (
			<React.Fragment>
				<div className={css(styles.bg)}></div>
				<MediaQuery minWidth={1224}>
					<Grid className={css(styles.grid)}>
						<Grid.Row className={css(styles.row)}>
							<Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8} className={css(styles.col)}>
						
								{activePage == "" || activePage == "/login" ? (
									<Login {...this.props} />
								) : null}

								{activePage == "/register" ? (
									<Register/>
								) : null}

								{activePage == "/forgot" ? (
									<Forgot/>
								) : null}

								{activePage == "/enroll" ? (
									<Enroll hash={this.props.location?.hash ?? null} />
								) : null}
								
								{activePage == "/sso_callback" ? (
									<SingleSignOn location={this.props.location}/>
								): null}

								{activePage == "/logout" ? (
									<Logout/>
								): null}
								
							</Grid.Column>
							<Grid.Column tablet={8} computer={8} largeScreen={8}>
								<Segment basic className={css(styles.logoContainer)}>
									<Image className={css(styles.logo)} src="/images/logo_dark.png" />
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</MediaQuery>
				<MediaQuery maxWidth={1223}>
					<Grid className={css(styles.grid)}>
						<Grid.Row className={css(styles.row)}>
							<Grid.Column width={16} className={css(styles.col)}>
								<Segment basic className={css(styles.mobile_logoContainer)}>
									<Image className={css(styles.mobile_logo)} src="/images/logo_light.png" />
								</Segment>

								{activePage == "" || activePage == "/login" ? (
									<Login {...this.props} height="unset" />
								) : null}

								{activePage == "/register" ? (
									<Register height="unset"/>
								) : null}

								{activePage == "/forgot" ? (
									<Forgot height="unset"/>
								) : null}

								{activePage == "/enroll" ? (
									<Enroll hash={this.props.location?.hash ?? null} height="unset" />
								) : null}

								{activePage == "/sso_callback" ? (
									<SingleSignOn height="unset" location={this.props.location}/>
								): null}

								{activePage == "/logout" ? (
									<Logout height="unset"/>
								): null}
								
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</MediaQuery>
			</React.Fragment>
		);
	}
}

const styles = StyleSheet.create({
	grid: {
		margin: 0,
		padding: 0,
		height: "100vh"
	},
	bg: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		padding: 0,
		margin: 0,
		background: "url(/images/login_bg.jpg)",
		filter:"grayscale(100%) brightness(0.4)",
		"background-size": "cover",
		"background-repeat": "no-repeat",
		"background-attachment": "fixed",
		"background-position": "top right",
	},
	col: {
		background: "rgba(255,255,255,0.8)",	
	},
	logoContainer: {
		height: '100vh',
		display: 'flex',
		alignItems: 'center'
	},	
	logo: {
		maxHeight: 130,
		margin: "0 auto"
	},
	mobile_grid: {
		margin: 0,
		padding: 0,
		height: 'calc(100vh - 250px)',
	},
	mobile_col: {
		background: "rgba(255,255,255,0.8)",	
	},
	
	mobile_logoContainer: {
		height: '130px',
		padding: "15px",
		display: 'flex',
		alignItems: 'center'
	},	
	mobile_logo: {
		maxHeight: 91,
		margin: "0 auto"
	},
	row: {
		padding: 0,
		margin: 0
	}
})