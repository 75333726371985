import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message, Select, Grid } from 'semantic-ui-react'
import { Translate, I18n } from "react-redux-i18n";
import { issue_create_request } from 'redux/issues/actions';
import { connect } from 'react-redux';
import { users_list_request } from 'redux/users/actions';

class Issue_Create extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			issue_type: "Bug Report",
			issue_priority: "Normal", 
			software_name: "eeforce", 
			software_version: "", 
			
			on_behalf_submitter: "self",

			subject: "", 
			description: "", 
			attachment: null, 
			errorMessage: ""
		}

		this.props.dispatch(users_list_request());
	}
	
	submitForm(){
		console.log("Submit");
		
		this.setState({errorMessage: null}, () => {
			let {open, errorMessage, ...rest} = this.state;
			this.props.dispatch(issue_create_request(rest))
			.then((resolved , rejected) => {
				if(resolved){
					this.close();
				}
			})
			.catch(err => {
				this.setState({errorMessage : err.message});
			})
		});
	}

	getSubmitterChoices(){
		let {role, name} = this.props.auth;
		let retVal = [{ key: name, value: "self", text: name}];
		
		this.props.users?.data?.forEach(user => {
			retVal.push({
				key: user._id,
				value: user._id,
				text: user.name + (user.customer ? ` (${user.customer.name})`: null)
			});
		});

		return retVal;
	}

	close(){
		this.setState({open: false});
	}

	render(){
		let status = false;
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true, subject: "", description: "", attachment: null, errorMessage: ""})} 
				open={this.state.open} 
				closeOnDimmerClick={false}
				size="large"
			>
				{!this.state.open ? null : ( 
					<React.Fragment>
						<Header icon='edit' content={"Report Issue"} />
						<Modal.Content>
							<Form onSubmit={() => this.onFormSubmit()}>
								<Grid>
									<Grid.Column width={8}>
										<Form.Select
											fluid
											search
											label="Submitter"
											options={this.getSubmitterChoices()}
											placeholder='Submitter'
											value={this.state.on_behalf_submitter}
											onChange={(e, data) => {this.setState({on_behalf_submitter : data.value})}}
										/>

										<Form.Select
											fluid
											label="Issue Type"
											options={[
												{ key: 'Bug Report', text: 'Bug Report', value: 'Bug Report' },
												{ key: 'Feature Request', text: 'Feature Request', value: 'Feature Request' },
											]}
											placeholder='Select Issue Type'
											value={this.state.issue_type}
											onChange={(e, data) => {this.setState({issue_type : data.value})}}
										/>

										<Form.Select
											fluid
											label="Issue Priority"
											options={[
												{ key: 'Normal', text: 'Normal', value: 'Normal' },
												{ key: 'Critical', text: 'Critical', value: 'Critical' },
											]}
											placeholder='Select Issue Priority'
											value={this.state.issue_priority}
											onChange={(e, data) => {this.setState({issue_priority : data.value})}}
										/>

										<Form.Select
											fluid
											label="Software"
											options={[
												{ key: 'eeforce', text: 'EEforce Version Management System', value: 'eeforce' }
											]}
											placeholder='Select Software'
											value={this.state.software_name}
											onChange={(e, data) => {this.setState({software_name : data.value})}}
										/>

										<Form.Input 
											type='text' 
											label="Software Version" 
											name="software_version" 
											placeholder='22.9' 
											value={this.state.software_version} 
											onChange={(e, data) => this.setState({software_version: data.value})} 
											
										/>
									</Grid.Column>
									<Grid.Column width={8}>
										<Form.Input 
											type='text' 
											label="Issue Subject" 
											name="subject" 
											placeholder='Please state a subject' 
											value={this.state.subject} 
											onChange={(e, data) => this.setState({subject: data.value})} 
											
										/>

										<Form.TextArea 
											type='text' 
											label="Issue Description" 
											name="description" 
											placeholder='Please explain the issue as detailed as possible' 
											value={this.state.description}
											rows="8"
											onChange={(e, data) => this.setState({description: data.value})} 
											
										/>

										<Form.Input
											type="file"
											label="Attachment"
											name="attachment"
											onChange={(e, data) => this.setState({attachment: e.target.files[0]})} 
											
										/>
									</Grid.Column>
								</Grid>		
							</Form>
							{this.state.errorMessage ? (
								<Message error>
									<Icon name='exclamation' />
									{this.state.errorMessage}
								</Message>
							) : null}
						</Modal.Content>
						<Modal.Actions>
							<Button 
								onClick={() => this.close()}
								content={I18n.t("issues.cancel")}
								color='red'
								icon="remove"
							/>
							<Button 
								onClick={() => this.submitForm()}
								content={I18n.t("issues.create_issue")}
								color='green'
								icon="checkmark"
							/>
						</Modal.Actions>
					</React.Fragment>
				)}
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	users: state.users
})
export default connect(mapStateToProps)(Issue_Create)