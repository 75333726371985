import React, { Component } from 'react';
import { Segment, Button, Form, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { user_login_request, user_logout_request } from 'redux/auth/actions';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'


class Login extends Component{
	constructor(props) {
		super(props);
		this.state={
			email: "",
			password: ""
		}
	}

	onFormSubmit(){
		this.props.dispatch(user_login_request(this.state.email, this.state.password));
	}
	requestLogOut(){
		this.props.dispatch(user_logout_request());
	}

	handleChange(e, data){
		switch(data.name){
			case "email": this.setState({email: data.value}); break;
			case "password": this.setState({password: data.value}); break;
			default: {}
		}
	}

	getSSOLink(){
		let {sso_enabled, sso_auth_url, sso_redirect_url, sso_client_id, sso_scope} = this.props.app.configs;
		if(sso_enabled){
			try{
				const sso_url = new URL(sso_auth_url);
				sso_url.searchParams.append("client_id", sso_client_id);
				sso_url.searchParams.append("redirect_uri", sso_redirect_url);
				sso_url.searchParams.append("response_type", "code");
				sso_url.searchParams.append("scope", sso_scope);
				return sso_url.href;
			}
			catch(err){
				return null;
			}
		} else {
			return null;
		}
	}

	render(){
		let {status, explanation} = this.props.auth;
		let {sso_enabled} = this.props.app.configs;

		if(status === "successful"){
			if(this.props.location.state?.from){
				return (<Redirect replace to={this.props.location.state?.from} />)	
			}
			return (<Redirect replace to="/releases" />)
		}
		return (
			<React.Fragment>
				<Grid textAlign='center' style={{ height: this.props.height ?? '100vh' }} verticalAlign='middle'>
					<Grid.Column style={{ maxWidth: 450 }}>
						<Header as='h2' color='black' textAlign='center'>
							Log-in to your account
						</Header>
						<Form onSubmit={() => this.onFormSubmit()}>
							<Segment stacked>
								<Form.Input 
									fluid icon='user' 
									iconPosition='left' 
									placeholder='E-mail address'
									name="email"
									type='email'
									onChange={(e, data) => this.handleChange(e, data)} 
									value={this.state.email}
								/>
							    <Link to="/forgot" style={{float: "right"}}>Forgot password?</Link>
								<Form.Input
									fluid
									icon='lock'
									iconPosition='left'
									placeholder='Password'
									type='password'
									name="password"
									onChange={(e, data) => this.handleChange(e, data)} 
									value={this.state.password}
								/>
								
								<Button loading={status === "pending"} color='blue' fluid size='large'>
									Login
								</Button>
							</Segment>
						</Form>
						{status === "failed" ? (
							<Message error>
								<Icon name='exclamation' />
								{explanation}
							</Message>
						) : null}

						<Message>
							Don't have an account yet? <Link to='/register'>Sign Up</Link>
						</Message>

						{sso_enabled ? ( <Message>
							<a href={this.getSSOLink()}>Employee Access</a>
						</Message>) : null}
					</Grid.Column>
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	app: state.app
})
export default connect(mapStateToProps)(Login)