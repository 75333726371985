import React, { Component } from 'react';
import { Message, Table, Grid, Item, Icon, Menu, Segment, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Translate, I18n } from "react-redux-i18n";
import { customers_list_request } from 'redux/customers/actions';
import Customers_Create from './Customers.Create';
import Customers_Modify from './Customers.Modify';
import { Link, withRouter } from 'react-router-dom';
class Customers extends Component{
	componentDidMount(){
		this.loadData();
	}

	loadData(){
		this.props.dispatch(customers_list_request());	
	}

	componentDidUpdate(oldProps){
		if(this.props.customers && this.props.customers.data && this.props.customers.data[0]){
			if(!this.props.match.params?._id){
				this.props.history.push("/customers/" + this.props.customers.data[0]._id);
			}
		}
	}

	getHighlight(item_id){
		let _id = this.props.match.params?._id;
		if(!_id) return null;
		return (_id == item_id)
	}

	getCurrentCustomer() {
		let _id = this.props.match.params?._id;
		if(!_id) return null;
		
		return this.props.customers.data.find(x=> x._id == _id);
	}

	render(){
		let currentCustomer = this.getCurrentCustomer();
		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:20, paddingLeft: 10}}>
							<Icon name="user"></Icon>
							<Header.Content>Manage Customers</Header.Content>
						</Header>
						</Grid.Column>
						<Grid.Column>
							<Menu compact icon="labeled" secondary size="tiny" floated="right">
								<Customers_Create dispatch={this.props.dispatch}>
									<Menu.Item
										name='Create'
										onClick={() => {this.loadData()}}
									>
										<Icon name="edit" />
										New Customer
									</Menu.Item>
								</Customers_Create>
								<Menu.Item
									name='Refresh'
									onClick={() => {this.loadData()}}
								>
									<Icon name="refresh" />
									Refresh
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{
						paddingTop: 0, 
						paddingLeft:0, 
						paddingRight: 12}}>
						<Grid.Column width={4} style={{
							paddingRight: 0,
						}}>
							<Menu fluid vertical pointing attached style={{
								"height" : window.innerHeight - 95
							}}>
								{
									this.props.customers.data.map(customer=>{
										return (
											<Menu.Item
												as={Link}
												to={"/customers/" + customer._id}
												key={customer._id}
												active={this.getHighlight(customer._id)}
											>
												<Item.Header>{customer.name ?? "Unkonwn Customer"}</Item.Header>
												<Item.Description></Item.Description>
												
											</Menu.Item>
										);
									})
								}
								{
									this.props.customers.loading ? (
										<Menu.Item>
											<Item.Header><Icon name='circle notched' loading /><Translate value="application.loadingData" /></Item.Header>
											<Item.Description></Item.Description>
										</Menu.Item>	
									): null 
								}
							</Menu>
							
						</Grid.Column>

						<Grid.Column width={12} style={{
							"backgroundColor": "white", 
							"border" : "1px solid #ddd", 
							"borderLeft": "none", 
							"paddingLeft" : 0,
							"overflowY" : "auto",
							"height" : window.innerHeight - 95
						}}>
							
							<Segment basic className="markdown-body">
								{currentCustomer ? (
									<React.Fragment>
										<Header dividing size="medium">Customer Details</Header>
										<Customers_Modify data={currentCustomer} dispatch={this.props.dispatch} />
									</React.Fragment>
									
								): "Select a customer"
								}
								
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	customers: state.customers
})
export default connect(mapStateToProps)(withRouter(Customers))