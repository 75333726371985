import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App';
import { store, persistor } from './redux/store'

import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css'
import './global.css';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);

// if (module.hot) {
// 	module.hot.accept('./App', () => {
// 		const NextApp = require('./App').default;
// 		render(NextApp);
// 	});
// }

// serviceWorker.unregister();