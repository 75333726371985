import {
	APP_SIDEBAR_TOGGLE,
	APP_LOAD_CONFIGS_ACTION,
	APP_LOAD_CONFIGS_SUCCESS,
	APP_LOAD_CONFIGS_FAILURE

} from '../actions'

const app = (state = {sidebar: true, configs : {status: "nil"}}, action) => {
	switch (action.type) {
		case APP_SIDEBAR_TOGGLE:
			return {
				...state,
				sidebar: !state.sidebar
			};
		case APP_LOAD_CONFIGS_ACTION:
			return {
				...state,
				configs: {
					status: "pending"
				},
			};
		case APP_LOAD_CONFIGS_FAILURE:
			return {
				...state,
				configs: {
					status: "failed",
					explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
				}
			};
		case APP_LOAD_CONFIGS_SUCCESS: 
			return {
				...state,
				configs: {
					status: "successful",
					...action.response.data,
				}
			};
		default:{
			return state
		}

	}
}

export default app;
