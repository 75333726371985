import React, { Component } from 'react';
import { Button, Header, Icon, Checkbox, Form, Message } from 'semantic-ui-react'
import { users_update_request, users_delete_request, users_enroll_request } from 'redux/users/actions';
import { Translate, I18n } from "react-redux-i18n";
import Prompt from 'pages/Prompt';

export default class Users_Modify extends Component{
	constructor(props){
		super(props);

		this.state = {
			email: "",
			name: "",
			role: "customer",
			customer: "",
			allow_notifications: true,
			active: false,
			note: "",

			changePassword: false,
			password: "",
			password2: "",

			errorMessage: "",
		}

		this.updateUser = this.updateUser.bind(this);
	}

	submitForm(){
		if(this.state.password !== this.state.password2){
			this.setState({errorMessage: 'Passwords does not match'});
		} else {
			this.setState({errorMessage: null}, () => {
				let {errorMessage, ...rest} = this.state;
				this.props.dispatch(users_update_request(this.props.data._id, rest))
				.then((resolved , rejected) => {
					if(resolved){
						
					}
				})
				.catch(err => {
					this.setState({errorMessage : err.message});
				})
			});
		}
	}

	componentDidMount(){
		this.updateUser();

	}
	componentDidUpdate(prevProps){
		if(this.props.data?._id !== prevProps.data?._id){
		  this.updateUser();
		}
	}

	updateUser(){
		this.setState({
			email: this.props.data.email,
			name: this.props.data.name,
			role: this.props.data.role,
			customer: this.props.data.customer?._id,
			allow_notifications: this.props.data.allow_notifications ?? false,
			active: this.props.data.active ?? false,
			note: this.props.data.note ?? "",

			changePassword: false,
			password: "",
			password2: "",
			errorMessage: ""
		});
	}

	deleteUser(userId){
		this.props.dispatch(users_delete_request(userId));
	}

	enrollUser(userId){
		this.props.dispatch(users_enroll_request(userId));
	}

	render(){
		return(
			<React.Fragment>
					<Form>
						<Form.Input label={I18n.t("user.name")} name="name" placeholder='Name' type='text' onChange={(e, data) => this.setState({name: data.value})} value={this.state.name} />
						<Form.Input label={I18n.t("user.email")} name="email" placeholder='E-mail' type='email' onChange={(e, data) => this.setState({email: data.value})} value={this.state.email} />
						<Form.Select
							fluid
							label={I18n.t("user.role")}
							options={[
								{ key: 'admin', text: 'Admin', value: 'admin' },
								{ key: 'executive', text: 'Executive', value: 'executive' },
								{ key: 'customer', text: 'Customer', value: 'customer' }
							]}
							placeholder='Select Role'
							value={this.state.role}
							onChange={(e, data) => {this.setState({role : data.value})}}
						/>

						{this.state.role === "customer" ? (
							<Form.Select
								fluid
								label={I18n.t("user.customer")}
								options={
									this.props.customers?.data?.map(cust => {
										return {
											key: cust._id,
											text: cust.siteID + " - " + cust.name,
											value: cust._id
										}
									})
								}
								placeholder='Select Role'
								value={this.state.customer}
								onChange={(e, data) => {this.setState({customer : data.value})}}
							/>
						) : null}

						<Form.Field>
							<Checkbox 
								label={I18n.t("user.receive_notification")}
								onChange={(e, data) => this.setState({allow_notifications: data.checked})} 
								checked={this.state.allow_notifications}

							/>
						</Form.Field>

						<Form.Field>
							<Checkbox 
								label={I18n.t("user.active")}
								onChange={(e, data) => this.setState({active: data.checked})} 
								checked={this.state.active}
							/>
						</Form.Field>

						<Form.TextArea 
							type='text' 
							label={I18n.t("user.notes")}
							name="notes" 
							placeholder='Notes about to this user' 
							value={this.state.note} 
							onChange={(e, data) => this.setState({note: data.value})} 
							
						/>					
					
						<Button 
							onClick={() => this.submitForm()}
							content="Update User"
						/>

						<Button
							onClick={() => this.enrollUser(this.props.data._id)}
							color="blue"
							basic
							content="Enroll User"
						/>

						<Prompt
							icon="red user delete"
							action={() => this.deleteUser(this.props.data._id)} 
							title={I18n.t("users.remove_title")}
							text={I18n.t("users.remove_text", {user: this.state.name})}>
							<Button 
								floated='right'
								color="red"
								basic
								content="Delete User"
							/>
						</Prompt>

						
					</Form>
					{this.state.errorMessage ? (
							<Message error>
								<Icon name='exclamation' />
								{this.state.errorMessage}
							</Message>
						) : null}
					
			</React.Fragment>
		)
	}
}