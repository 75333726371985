import {
	ISSUE_LOAD_ACTION,
	ISSUE_LOAD_SUCCESS,
	ISSUE_LOAD_FAILURE
} from '../actions'

const issue_view = (state = {data: {}, loading: true}, action) => {
	switch (action.type) {
		case ISSUE_LOAD_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case ISSUE_LOAD_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case ISSUE_LOAD_FAILURE:
			return {
				...state,
				data: {},
				loading: false
			};
		default:{
			return state
		}
			
	}
}

export default issue_view;
