// @flow
import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { connect as wsConnect, disconnect as wsDisconnect } from '@giantmachines/redux-websocket';
import { store } from './redux/store';

import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import Notifications from 'react-notification-system-redux';



// import { tr } from './lang/tr';
import { en } from './lang/en';


import SideMenu from './components/SideMenu';

import Welcome from 'pages/WelcomePage/Welcome';
import Profile from 'pages/Profile/Profile';
import Users from 'pages/Users/Users';

import Customers from 'pages/Customers/Customers';

import License_Files from 'pages/License_Files/License_Files';
import Releases from 'pages/Releases/Releases';
import Issues from 'pages/Issues/Issues';
import Issue_View from 'pages/Issues/Issue_View';
import {PrivateRoute} from './tools/PrivateRoute';
import {app_load_configs} from 'redux/app/actions';

const appHistory = createBrowserHistory();

type Props = {
	auth: Object,
	notifications: Object
};
class App extends Component<Props> {

	componentDidMount(){		
		store.dispatch(wsConnect(String(process.env.REACT_APP_SERVER_URL).replace("https", "wss").replace("http", "ws") + "/ws"));
		store.dispatch(app_load_configs());

		syncTranslationWithStore(store)
		store.dispatch(loadTranslations({
			// tr: tr,
			en: en
		}));
		if(!store.getState().i18n.locale){
			store.dispatch(setLocale("en"));
		}
	}

	componentWillUnmount(){
		store.dispatch(wsDisconnect());
	}

	

	render() {
		let {reauthing} = this.props.auth;
		if(reauthing) return (
			<Dimmer active inverted>
				<Loader inverted>Verifiying Authorization</Loader>
			</Dimmer>
		)
		else 
			return (
				<Router history={appHistory}>
					<Switch>	
						<PrivateRoute exact path="/">
							<Releases />
						</PrivateRoute>

						<PrivateRoute exact path="/customers">
							<Customers />
						</PrivateRoute>
						<PrivateRoute exact path="/customers/:_id">
							<Customers />
						</PrivateRoute>
						

						<PrivateRoute exact path="/users">
							<Users />
						</PrivateRoute>
						
						<PrivateRoute exact path="/users/:_id">
							<Users />
						</PrivateRoute>
						

						<PrivateRoute exact path="/releases">
							<Releases />
						</PrivateRoute>
						
						<PrivateRoute exact path="/releases/:_id">
							<Releases />
						</PrivateRoute>
						
						<PrivateRoute exact path="/licenses/">
							<License_Files />
						</PrivateRoute>
						<PrivateRoute exact path="/licenses/:_id">
							<License_Files />
						</PrivateRoute>


						<PrivateRoute exact path="/issues">
							<Issues />
						</PrivateRoute>

						<PrivateRoute exact path="/issues/:_id">
							<Issue_View />
						</PrivateRoute>
						
						<PrivateRoute exact path="/profile">
							<Profile />
						</PrivateRoute>			
						<Route exact path="/login" component={Welcome} />
						<Route exact path="/register" component={Welcome} />
						<Route exact path="/forgot" component={Welcome} />
						<Route exact path="/enroll" component={Welcome} />
						<Route exact path="/sso_callback" component={Welcome} />
						<Route exact path="/logout" component={Welcome} />
					
						<Route path="*">
							<Redirect replace to="/" />
						</Route>
					</Switch>
					
					<Notifications
						notifications={this.props.notifications}
					/>
				</Router>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	app: state.app,
	notifications: state.notifications
})
export default connect(mapStateToProps)(App)