import React, { Component } from 'react';
import { Button, Header, Icon, Checkbox, Form, Message, Grid, Segment, Label } from 'semantic-ui-react'
import { Translate, I18n } from "react-redux-i18n";
import { connect } from 'react-redux';
import { users_change_password_request } from 'redux/auth/actions';

class ChangePassword extends Component{
	constructor(props){
		super(props);

		this.state = {
			current_password: "",
			new_password: "",
			new_password2: ""
		}
	}

	handleChange(e, data){
		switch(data.name){
			case "current_password": this.setState({current_password: data.value}); break;
			case "new_password": this.setState({new_password: data.value}); break;
			case "new_password2": this.setState({new_password2: data.value}); break;
			default: {}
		}
	}

	onFormSubmit(){
		this.props.dispatch(users_change_password_request(this.state.current_password, this.state.new_password, this.state.new_password2));
	}

	render(){
		let {password_update_status, password_update_error_explanation} = this.props.auth;
		return (
			<React.Fragment>			
				<Header as="h4" attached="top" content={I18n.t("user.change_password_title")} />
				<Segment attached="bottom">			
					<Form 
						error={password_update_status === "failed"} 
						onSubmit={() => this.onFormSubmit()}>
						<Form.Input
							label={I18n.t("user.current_password")}  
							fluid
							icon='lock'
							iconPosition='left'
							placeholder='Current Password'
							type='password'
							name="current_password"
							onChange={(e, data) => this.handleChange(e, data)} 
							value={this.state.current_password}
							required
						/>

						<Form.Input
							label={I18n.t("user.new_password")}  
							fluid
							icon='lock'
							iconPosition='left'
							placeholder='New Password'
							type='password'
							name="new_password"
							onChange={(e, data) => this.handleChange(e, data)} 
							value={this.state.new_password}
							required
						/>
						<Form.Input
							label={I18n.t("user.new_password2")}  
							fluid
							icon='lock'
							iconPosition='left'
							placeholder='New Password (again)'
							type='password'
							name="new_password2"
							onChange={(e, data) => this.handleChange(e, data)} 
							value={this.state.new_password2}
							required
						/>

						<Button 		
							loading={password_update_status === "pending"} 
							content={I18n.t("user.change_password_button")}  
						/>
					
						{password_update_status === "failed" ?(
							<Message
								error
								header={I18n.t("user.change_password_error_title")}  
								list={password_update_error_explanation.error_reasons ?? [password_update_error_explanation]}
							/>
						) : null}					
					</Form>
				</Segment>
						
			</React.Fragment>
		);
	}
}
const mapStateToProps = state => ({
	auth: state.auth,
})
export default connect(mapStateToProps)(ChangePassword)