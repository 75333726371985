import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message, Checkbox, Segment } from 'semantic-ui-react'
import { Translate, I18n } from "react-redux-i18n";
import { customers_modify_request } from 'redux/customers/actions';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import 'style/markdown.css';
import { license_file_update_request } from 'redux/license_files/actions';

class License_Files_Modify extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			cust_id: "",
			content: "",
			replace_file: false, 
			file: null,
			show_markdown: false,
			errorMessage: ""
		}

		this.updateContent = this.updateContent.bind(this);
	}

	componentDidMount(){
		this.updateContent();

	}

	updateContent(){
		this.setState({
			cust_id: this.props.data?.customer?._id,
			content: this.props.data?.content, 
			replace_file: false,
			file: null,
			show_markdown: false,
			errorMessage: ""
		});
	}

	componentDidUpdate(prevProps){
		if(this.props.data?._id !== prevProps.data?._id){
		  this.updateContent();
		}
	}
	
	submitForm(){
		console.log("Submit");
		
		this.setState({errorMessage: null}, () => {
			
			let {open, errorMessage, show_markdown, ...rest} = this.state;
			this.props.dispatch(license_file_update_request(this.props.data._id, rest))
			.then((resolved , rejected) => {
				if(resolved){
					this.close();
				}
			})
			.catch(err => {
				this.setState({errorMessage : err.message});
			})
		});
	}

	close(){
		this.setState({open: false});
	}
	render(){
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				closeOnDimmerClick={false} 
			>
				<Header icon='plus' content={"Create License File"} />
				<Modal.Content>
					<Form onSubmit={() => this.onFormSubmit()}>

					
						<Form.Select
							fluid
							label="Customer"
							options={
								this.props.customers.data.map(cust => {
									return {
										key: cust._id,
										text: cust.name,
										value: cust._id
									}
								})
							}
							placeholder='Select Customer'
							value={this.state.cust_id}
							onChange={(e, data) => {this.setState({cust_id : data.value})}}
						/>						

						<Form.Field>
							<Checkbox 
								toggle 
								label='Replace File' 
								onChange={(e, data) => this.setState({replace_file: data.checked})} 
								checked={this.state.replace_file}
							/>
						</Form.Field>
						<Form.Input
							type="file"
							disabled={!this.state.replace_file}
							label="Attachment"
							name="attachment"
							onChange={(e, data) => this.setState({file: e.target.files[0]})} 
						/>

						<Form.Field>
							<Checkbox 
								toggle 
								label='Show Markdown' 
								onChange={(e, data) => this.setState({show_markdown: data.checked})} 
								checked={this.state.show_markdown}
							/>
						</Form.Field>

						{this.state.show_markdown ? (
							<Segment className="markdown-body" style={{maxHeight: 300, overflowY: "auto"}}>
								<ReactMarkdown remarkPlugins={[remarkGfm]}>{this.state.content}</ReactMarkdown>
							</Segment>
						) : (
							<Form.Input 
								label="Content" 
								name="Content" 
								placeholder='Content of License file (Markdown)' 
								type='text' 
								control="textarea"
								onChange={(e, data) => {console.log(e, data); this.setState({content: e.target.value});}} 
								value={this.state.content} 
							/>
						)}
					
						
						
					</Form>
					{this.state.errorMessage ? (
						<Message error>
							<Icon name='exclamation' />
							{this.state.errorMessage}
						</Message>
					) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("users_create.cancel")}
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content={I18n.t("users_create.create")}
						color='green'
						icon="checkmark"
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}


const mapStateToProps = state => ({
	customers: state.customers
})
export default connect(mapStateToProps)(License_Files_Modify)