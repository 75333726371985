import React, { Component } from 'react';
import { Message, Table, Grid, Item, Icon, Menu, Segment, Header, Dimmer, Loader } from 'semantic-ui-react';
import Users_Create from './Users.Create';
import {users_list_request} from 'redux/users/actions';
import { connect } from 'react-redux';
import Users_Modify from './Users.Modify';
import { Translate, I18n } from "react-redux-i18n";
import { Link, withRouter } from 'react-router-dom';
import { customers_list_request } from 'redux/customers/actions';

class Users extends Component{
	componentDidMount(){
		this.loadData();
	}
	loadData(){
		this.props.dispatch(users_list_request());
		this.props.dispatch(customers_list_request());

		
	}

	componentDidUpdate(oldProps){
		if(this.props.users && this.props.users.data && this.props.users.data[0]){
			if(!this.props.match.params?._id){
				this.props.history.push("/users/" + this.props.users.data[0]._id);
			}
		}
	}

	getHighlight(item_id){
		let _id = this.props.match.params?._id;
		if(!_id) return null;
		return (_id == item_id)
	}

	getCurrentUser() {
		let _id = this.props.match.params?._id;
		if(!_id) return null;
		
		return this.props.users.data.find(x=> x._id == _id);
	}


	render(){
		if(this.props.users.loading){
			return (
				<Dimmer active inverted style={{height: "100vh"}}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			)
		}


		let currentUser = this.getCurrentUser();
		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:20, paddingLeft: 10}}>
							<Icon name="user"></Icon>
							<Header.Content>Manage Users</Header.Content>
						</Header>
						</Grid.Column>
						<Grid.Column>
							<Menu compact icon="labeled" secondary size="tiny" floated="right">
								<Users_Create dispatch={this.props.dispatch}>
									<Menu.Item
										name='Create'
									>
										<Icon name="edit" />
										Create User
									</Menu.Item>
								</Users_Create>
								<Menu.Item
									name='Refresh'
									onClick={() => {this.loadData()}}
								>
									<Icon name="refresh" />
									Refresh
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{
						paddingTop: 0, 
						paddingLeft:0, 
						paddingRight: 12}}>
						<Grid.Column width={4} style={{
							paddingRight: 0,
						}}>
							<Menu fluid vertical pointing attached style={{
								"height" : window.innerHeight - 95
							}}>
								{
									this.props.users.data.map(user=>{
										return (
											<Menu.Item
												as={Link}
												to={"/users/" + user._id}
												key={user._id}
												active={this.getHighlight(user._id)}
												
											>
												<Item.Header>{user.name ?? "Unkonwn Name"}</Item.Header>
												<Item.Description>{user.customer? user.customer.name : user.role}</Item.Description>
												
											</Menu.Item>
										);
									})
								}
							</Menu>
							
						</Grid.Column>

						<Grid.Column width={12} style={{
							"backgroundColor": "white", 
							"border" : "1px solid #ddd", 
							"borderLeft": "none", 
							"paddingLeft" : 0,
							"overflowY" : "auto",
							"height" : window.innerHeight - 95
						}}>
							
							<Segment basic className="markdown-body">
								{currentUser ? (
									<React.Fragment>
										<Header dividing size="medium">User Details</Header>
										<Users_Modify data={currentUser} customers={this.props.customers} dispatch={this.props.dispatch} />
									</React.Fragment>
									
								): "Select an user"
								}
								
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	users: state.users,
	customers: state.customers
})
export default connect(mapStateToProps)(withRouter(Users))