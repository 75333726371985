import React, { Component } from 'react';
import { Button, Header, Icon, Checkbox, Form, Message } from 'semantic-ui-react'

import { Translate, I18n } from "react-redux-i18n";
import Prompt from 'pages/Prompt';
import { customers_update_request, customers_delete_request } from '../../redux/customers/actions';

export default class Customers_Modify extends Component{
	constructor(props){
		super(props);

		this.state = {
			siteID: "", 
			name: "", 
			active: true,
			errorMessage: "",
		}

		this.updateCustomer = this.updateCustomer.bind(this);
	}

	submitForm(){
		if(this.state.password !== this.state.password2){
			this.setState({errorMessage: 'Passwords does not match'});
		} else {
			this.setState({errorMessage: null}, () => {
				let {errorMessage, ...rest} = this.state;
				this.props.dispatch(customers_update_request(this.props.data._id, rest))
				.then((resolved , rejected) => {
					if(resolved){
						
					}
				})
				.catch(err => {
					this.setState({errorMessage : err.message});
				})
			});
		}
	}

	componentDidMount(){
		this.updateCustomer();

	}
	componentDidUpdate(prevProps){
		if(this.props.data?._id !== prevProps.data?._id){
		  this.updateCustomer();
		}
	}

	updateCustomer(){
		this.setState({
			siteID: this.props.data.siteID, 
			name: this.props.data.name, 
			active: this.props.data?.active ?? false, 
			errorMessage: ""
		});
	}

	deleteCustomer(customer_id){
		this.props.dispatch(customers_delete_request(customer_id));
	}

	render(){
		return(
			<React.Fragment>
					<Form>
						<Form.Input label="Site ID" name="siteID" placeholder='Site ID#' type='text' onChange={(e, data) => this.setState({siteID: data.value})} value={this.state.siteID} />
						<Form.Input label="Name" name="name" placeholder='Name#' type='text' onChange={(e, data) => this.setState({name: data.value})} value={this.state.name} />					
						<Form.Field>
							<Checkbox 
								label='Customer Accounts Active' 
								onChange={(e, data) => this.setState({active: data.checked})} 
								checked={this.state.active}
							/>
						</Form.Field>

						<Button 
							onClick={() => this.submitForm()}
							content="Update Customer"
						/>
					
					<Prompt
						icon="red user delete"
						action={() => this.deleteCustomer(this.props.data._id)} 
						title={I18n.t("users.remove_title")}
						text={I18n.t("users.remove_text", {user: this.state.name})}>
						<Button 
							floated='right'
							color="red"
							basic
							content="Delete Customer"
						/>
					</Prompt>

						
					</Form>
					{this.state.errorMessage ? (
							<Message error>
								<Icon name='exclamation' />
								{this.state.errorMessage}
							</Message>
						) : null}
					
			</React.Fragment>
		)
	}
}