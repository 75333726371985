import React, { Component } from 'react';
import { Segment, Button, Form, Image, Menu, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { user_forgot_request } from 'redux/auth/actions';
import { connect } from 'react-redux'
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom'

class Forgot extends Component{
	constructor(props) {
		super(props);
		this.state={
			email: ""
		}
	}

	onFormSubmit(){
		this.props.dispatch(user_forgot_request(this.state.email));
	}

	handleChange(e, data){
		switch(data.name){
			case "email": this.setState({email: data.value}); break;
			default: {}
		}
	}

	render(){
		let {forgot_status, explanation} = this.props.auth;
		return (
			<React.Fragment>
				<Grid textAlign='center' style={{ height: this.props.height ?? '100vh' }} verticalAlign='middle'>
					<Grid.Column style={{ maxWidth: 450 }}>
						<Header as='h2' color='black' textAlign='center'>
							Forgot your password?
						</Header>
						<Form onSubmit={() => this.onFormSubmit()}>
							{forgot_status !== "successful" ? (
								<Segment stacked>
									<p>
										Please enter your registered e-mail address below. We will send you a password reset link.
									</p>

									<Form.Input 
										fluid icon='user' 
										iconPosition='left' 
										placeholder='E-mail Address'
										name="email"
										type='email'
										onChange={(e, data) => this.handleChange(e, data)} 
										value={this.state.email}
									/>

									<Button loading={forgot_status === "pending"} color='blue' fluid size='large'>
										Proceed
									</Button>
								</Segment>
							) : null}
							
							{forgot_status === "successful" ? (
								<Segment stacked>
									<Message positive>
										<Message.Header>Request Received</Message.Header>
										<p>
											We will send you a temporary password if we have your account registered...
										</p>
									</Message>
								</Segment>
							): null}
						</Form>
						{forgot_status === "failed" ? (
							<Message error>
								<Icon name='exclamation' />
								{explanation}
							</Message>
						) : null}

						<Message>
							Return back to <Link to='/login'>Log In</Link>
						</Message>
					</Grid.Column>
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
})
export default connect(mapStateToProps)(Forgot)