import React, { Component } from 'react';
import { Segment, Button, Form, Image, Menu, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { user_register_request } from 'redux/auth/actions';
import { connect } from 'react-redux'
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom'

class Register extends Component{
	constructor(props) {
		super(props);
		this.state={
			email: "",
			site_id: "",
			name: ""
		}
	}

	onFormSubmit(){
		this.props.dispatch(user_register_request(this.state.email, this.state.name, this.state.site_id));
	}

	handleChange(e, data){
		switch(data.name){
			case "email": this.setState({email: data.value}); break;
			case "site_id": this.setState({site_id: data.value}); break;
			case "name": this.setState({name: data.value}); break;
			default: {}
		}
	}

	render(){
		let {register_status, explanation} = this.props.auth;
		return (
			<React.Fragment>
				<Grid textAlign='center' style={{ height: this.props.height ?? '100vh' }} verticalAlign='middle'>
					<Grid.Column style={{ maxWidth: 450 }}>
						<Header as='h2' color='black' textAlign='center'>
							Register a new account
						</Header>
						<Form onSubmit={() => this.onFormSubmit()}>
							{register_status !== "successful" ? (
								<Segment stacked>
									<p>
										You will receive an e-mail from no-reply@milbitt.com once we verify your company details. 
										Please make sure that you're entering a correct site id and using company e-mail address.<br/> 
										This may take some time up to two business days.
									</p>

									<Form.Input 
										fluid icon='user' 
										iconPosition='left' 
										placeholder='Name'
										name="name"
										onChange={(e, data) => this.handleChange(e, data)} 
										value={this.state.name}
									/>
									<Form.Input 
										fluid icon='user' 
										iconPosition='left' 
										placeholder='E-mail Address'
										name="email"
										type='email'
										onChange={(e, data) => this.handleChange(e, data)} 
										value={this.state.email}
									/>

									<Form.Input 
										fluid icon='globe' 
										iconPosition='left' 
										placeholder='Customer Site ID'
										name="site_id"
										onChange={(e, data) => this.handleChange(e, data)} 
										value={this.state.site_id}
									/>
									
									
									<Button loading={register_status === "pending"} color='blue' fluid size='large'>
										Register
									</Button>
								</Segment>
							) : null}
							
							{register_status === "successful" ? (
								<Segment stacked>
									<Message positive>
										<Message.Header>Request Received</Message.Header>
										<p>
											We will process your request as soon as possible. Please keep monitoring your inbox.
										</p>
									</Message>
								</Segment>
							): null}
						</Form>
						{register_status === "failed" ? (
							<Message error>
								<Icon name='exclamation' />
								{explanation}
							</Message>
						) : null}

						<Message>
							Have an account already? <Link to='/login'>Log In</Link>
						</Message>
					</Grid.Column>
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
})
export default connect(mapStateToProps)(Register)