import axios from 'axios';
import {store} from '../store';
import { createAction } from 'redux-create-action';

import {
	RELEASES_LIST_ACTION,
	RELEASES_LIST_SUCCESS,
	RELEASES_LIST_FAILURE
} from '../actions';


const software_releases_list_set_pending 		= createAction(RELEASES_LIST_ACTION);
const software_releases_list_set_successful 	= createAction(RELEASES_LIST_SUCCESS, 'response');
const software_releases_list_set_failed 		= createAction(RELEASES_LIST_FAILURE);


// const software_releases_create_set_pending 		= createAction(PRODUCT_CREATE_ACTION);
// const software_releases_create_set_successful 	= createAction(PRODUCT_CREATE_SUCCESS, 'response');
// const software_releases_create_set_failed 		= createAction(PRODUCT_CREATE_FAILURE);
// const software_releases_update_set_pending 		= createAction(PRODUCT_UPDATE_ACTION);
// const software_releases_update_set_successful 	= createAction(PRODUCT_UPDATE_SUCCESS, 'response');
// const software_releases_update_set_failed 		= createAction(PRODUCT_UPDATE_FAILURE);


export const software_releases_list_request = () => dispatch => {
	dispatch(software_releases_list_set_pending());

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/software_releases/',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(software_releases_list_set_successful(res.data));
		} else {
			dispatch(software_releases_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(software_releases_list_set_failed());
	});
}

export const software_releases_refresh_request = () => dispatch => {

	axios.get(process.env.REACT_APP_SERVER_URL + '/api/software_releases/',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(software_releases_list_set_successful(res.data));
		} else {
			dispatch(software_releases_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(software_releases_list_set_failed());
	});
}

export const software_releases_create_request = (data) => dispatch => {
	return new Promise((resolve, reject) => {
		let form_data = new FormData();
		for ( var key in data ) {
			form_data.append(key, data[key]);
		}
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/software_releases/create', form_data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 201) {
				dispatch(software_releases_refresh_request());
				resolve(true);
			} else {
				reject(res);
			}
		})
		.catch((err) => {
			reject(err.response.data);
		});
	});
}

export const software_releases_update_request = (item_id, data) => dispatch => {
	return new Promise((resolve, reject) => {
		let form_data = new FormData();
		for ( var key in data ) {
			form_data.append(key, data[key]);
		}
		axios.post(process.env.REACT_APP_SERVER_URL + '/api/software_releases/update/' + item_id, form_data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(software_releases_refresh_request());
				resolve(true);
			} else {
				reject(res);
			}
		})
		.catch((err) => {
			reject(err.response.data);
		});
	});
}

export const software_releases_delete_request = (item_id) => dispatch => {
	return new Promise((resolve, reject) => {
		
		axios.get(process.env.REACT_APP_SERVER_URL + '/api/software_releases/delete/' + item_id,{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(software_releases_refresh_request());
				resolve(true);
			} else {
				reject(res);
			}
		})
		.catch((err) => {
			reject(err.response.data);
		});
	});
}
