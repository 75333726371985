import React, { Component } from 'react';
import { Button, Header, Icon, Checkbox, Form, Message, Grid, Segment, Label } from 'semantic-ui-react'
import { users_update_profile_request } from 'redux/auth/actions';
import { Translate, I18n } from "react-redux-i18n";
import Prompt from 'pages/Prompt';
import { connect } from 'react-redux';
import ChangePassword from './ChangePassword';
class Profile extends Component{
	constructor(props){
		super(props);

		this.state = {
			name: "",
			allow_notifications: false,

			errorMessage: "",
		}

		this.loadData = this.loadData.bind(this);
	}

	
	
	componentDidMount(){
		this.loadData();

	}
	componentDidUpdate(prevProps){
		if(this.props.auth?._id !== prevProps.auth?._id){
		  this.loadData();
		}
	}

	loadData(){
		this.setState({
			name: this.props.auth.name,
			allow_notifications: this.props.auth.allow_notifications ?? false,
		});
	}

	onFormSubmit(){
		this.props.dispatch(users_update_profile_request(this.state.name, this.state.allow_notifications));
	}

	render(){
		let {profile_update_status, profile_update_error_explanation} = this.props.auth;
		
		let profile_update_enabled = (
			this.props.auth.name !== this.state.name ||
			(this.props.auth.allow_notifications ?? false) !== this.state.allow_notifications);		

		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column width={16}>
							<Header as='h3' style={{paddingTop:20, paddingLeft: 10, paddingBottom: 10, marginBottom: 10}}>
								<Icon name="edit"></Icon>
								<Header.Content>My Profile</Header.Content>
							</Header>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{paddingTop: 0}}>
						<Grid.Column width={16}>
							<Header as="h4" attached="top" content={I18n.t("user.basic_profile_title")} />
							<Segment attached="bottom">	
								<Form 
								error={profile_update_status === "failed"}
								onSubmit={() => this.onFormSubmit()}
								>
									
										<Form.Input 
										 	icon='lock' 
											iconPosition='left'
											label={I18n.t("user.customer")} 
											name="customer" 
											placeholder='Customer' 
											type='text' 
											value={this.props.auth.customer?.name ?? "Unknown"} 
										/>

										<Form.Input 
											icon='lock' 
											iconPosition='left'
											label={I18n.t("user.email")} 
											name="email" 
											placeholder='E-mail' 
											type='email' 
											value={this.props.auth.email}
										/>
										
										<Form.Input 
											label={I18n.t("user.name")} 
											name="name" 
											placeholder='Name' 
											type='text' 
											onChange={(e, data) => this.setState({name: data.value})} 
											value={this.state.name} 
											required
										/>

										

										<Form.Field>
											<Checkbox 
												label={I18n.t("user.receive_notification")} 
												onChange={(e, data) => this.setState({allow_notifications: data.checked})} 
												checked={this.state.allow_notifications}

											/>
										</Form.Field>
										<Button 
											disabled={!profile_update_enabled}
											content={I18n.t("user.update_profile")} 
										/>
										{profile_update_status === "failed" ?(
											<Message
												error
												header={I18n.t("user.update_profile_error_title")}  
												list={profile_update_error_explanation.error_reasons ?? [profile_update_error_explanation]}
											/>
										) : null}	
								</Form>
							</Segment>

							<ChangePassword />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</React.Fragment>
		);
	}
}
const mapStateToProps = state => ({
	auth: state.auth,
})
export default connect(mapStateToProps)(Profile)