import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message, Checkbox } from 'semantic-ui-react'
import { users_create_request } from 'redux/users/actions';
import { Translate, I18n } from "react-redux-i18n";
import { connect } from 'react-redux';

class Users_Create extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			email: "",
			allow_notifications: true,
			active: true,
			name: "",
			role: "customer",
			note: "",
			customer: null,
			errorMessage: ""
		}
	}
	
	submitForm(){
			
		if(this.state.password !== this.state.password2){
			this.setState({errorMessage: 'Passwords does not match'});
		} else {
			this.setState({errorMessage: null}, () => {
				let {open, errorMessage, ...rest} = this.state;
				this.props.dispatch(users_create_request(rest))
				.then((resolved , rejected) => {
					if(resolved){
						this.close();
					}
				})
				.catch(err => {
					this.setState({errorMessage : err.message});
				})
			});
		}
	}

	close(){
		this.setState({open: false});
	}

	render(){
		let status = false;
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				closeOnDimmerClick={false} 
			>
				<Header icon='user plus' content={I18n.t("users_create.header")} />
				<Modal.Content>
					<Form onSubmit={() => this.onFormSubmit()}>
						<Form.Input label={I18n.t("users_create.name")}  name="name" placeholder='Name' type='text' onChange={(e, data) => this.setState({name: data.value})} value={this.state.name} />
						<Form.Input label={I18n.t("users_create.email")} name="email" placeholder='E-mail' type='email' onChange={(e, data) => this.setState({email: data.value})} value={this.state.email} />
						
						<Form.Select
							fluid
							label={I18n.t("users_create.role")}
							options={[
								{ key: 'admin', text: 'Admin', value: 'admin' },
								{ key: 'executive', text: 'Executive', value: 'executive' },
								{ key: 'customer', text: 'Customer', value: 'customer' }
							]}
							placeholder='Select Role'
							value={this.state.role}
							onChange={(e, data) => {this.setState({role : data.value})}}
						/>

						{this.state.role === "customer" ? (
							<Form.Select
								fluid
								label={I18n.t("users_create.customer")}
								options={
									this.props.customers.data.map(cust => {
										return {
											key: cust._id,
											text: cust.name,
											value: cust._id
										}
									})
								}
								placeholder='Select Role'
								value={this.state.customer}
								onChange={(e, data) => {this.setState({customer : data.value})}}
							/>
						) : null}

						<Form.Field>
							<Checkbox 
								label='Receives Notifications' 
								onChange={(e, data) => this.setState({allow_notifications: data.checked})} 
								checked={this.state.allow_notifications}

							/>
						</Form.Field>


						<Form.TextArea 
							type='text' 
							label="Notes" 
							name="notes" 
							placeholder='Notes about to this user' 
							value={this.state.note} 
							onChange={(e, data) => this.setState({note: data.value})} 
							
						/>

						<Form.Field>
							<Checkbox 
								label='Account Active' 
								onChange={(e, data) => this.setState({active: data.checked})} 
								checked={this.state.active}
							/>
						</Form.Field>
						
					</Form>
					{this.state.errorMessage ? (
						<Message error>
							<Icon name='exclamation' />
							{this.state.errorMessage}
						</Message>
					) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("users_create.cancel")}
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content={I18n.t("users_create.create")}
						color='green'
						icon="checkmark"
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
	customers: state.customers
})
export default connect(mapStateToProps)(Users_Create)